import '../scss/spectrum.scss';
import '../scss/main.scss';
import scss_vars from '../scss/modules/_vars.scss';
import {shows_pieces} from './shows_pieces.js';

/*global jQuery, fabric, tinycolor, Dropzone */
jQuery($ => {
  'use strict';
  const
    //dom
    $canvas_puzzle = $('#canvas_puzzle'),
    $canvas_cont = $canvas_puzzle.closest('.canvas_cont'),
    $edge_compat_note = $('#edge_compat_note'),
    $mail_contact = $('#mail_contact'),

    $controls_puzzle = $('#controls_puzzle'),
    $control_canvas_bg = $('#control_canvas_bg'),
    $control_canvas_size = $('#control_canvas_size'),
    $control_canvas_h_minus = $('#control_canvas_h_minus'),
    $control_canvas_h_plus = $('#control_canvas_h_plus'),
    $control_canvas_w_minus = $('#control_canvas_w_minus'),
    $control_canvas_w_plus = $('#control_canvas_w_plus'),
    $control_canvas_size_btns = $control_canvas_h_minus.add($control_canvas_h_plus).add($control_canvas_w_minus).add($control_canvas_w_plus),

    $control_pan_zoom_reset = $('#control_pan_zoom_reset'),
    $control_wheel_zooms = $('#control_wheel_zooms'),

    $control_pieces_load = $('#control_pieces_load'),
    $control_pieces_load_lbl = $('#control_pieces_load_lbl'),
    $control_pieces_load_fallback_cont = $('#control_pieces_load_fallback_cont'),
    $control_pieces_load_fallback = $('#control_pieces_load_fallback'),

    $control_pieces_load_show = $('#control_pieces_load_show'),
    $control_pieces_name_toggle = $('#control_pieces_name_toggle'),
    $control_pieces_name_color = $('#control_pieces_name_color'),
    $control_pieces_name_size = $('#control_pieces_name_size'),

    $control_spiece_rotate_reset = $('#control_spiece_rotate_reset'),
    $control_spiece_rotate_left = $('#control_spiece_rotate_left'),
    $control_spiece_rotate_right = $('#control_spiece_rotate_right'),
    $control_spiece_scale_reset = $('#control_spiece_scale_reset'),
    $control_spiece_scale_plus = $('#control_spiece_scale_plus'),
    $control_spiece_scale_minus = $('#control_spiece_scale_minus'),
    $control_spiece_flip_reset = $('#control_spiece_flip_reset'),
    $control_spiece_details = $('#control_spiece_details'),
    $control_spiece_name = $('#control_spiece_name'),
    $control_spiece_remove = $('#control_spiece_remove'),

    $local_storage_supported = $('.local_storage_supported'),
    $control_save_img = $('#control_save_img'),
    $control_save_local = $('#control_save_local'),
    $control_save_local_clear = $('#control_save_local_clear'),
    $control_save_json = $('#control_save_json'),
    $control_load_json = $('#control_load_json'),
    $control_load_json_fallback_cont = $('#control_load_json_fallback_cont'),
    $control_load_json_fallback = $('#control_load_json_fallback'),
    control_save_img_link = document.createElement('a'),
    control_save_json_link = document.createElement('a'),

    $control_save_online = $('#control_save_online'),
    $control_save_online_disabled = $('#control_save_online_disabled'),
    $control_load_online = $('#control_load_online'),
    $control_load_online_disabled = $('#control_load_online_disabled'),

    $control_puzzle_status = $('#control_puzzle_status'),

    $piece_load_popup = $('#piece_load_popup'),
    $piece_load_popup_cont = $('#piece_load_popup_cont'),
    $popup_pieces_cont = $piece_load_popup.find('.pieces_wrap'),
    $piece_load_popup_load = $('#piece_load_popup_load'),
    $piece_load_popup_close = $('#piece_load_popup_close'),

    $oload_osave_supported = $('.oload_osave_supported'),

    $oload_popup = $('#oload_popup'),
    $oload_popup_close = $('#oload_popup_close'),
    $oload_popup_key_input = $('#oload_popup_key_input'),
    $oload_popup_load_key_input = $('#oload_popup_load_key_input'),
    $oload_popup_load_key = $('#oload_popup_load_key'),
    $oload_popup_load_key_status = $('#oload_popup_load_key_status'),
    $oload_loading = $('#oload_loading'),
    $oload_list_cont = $('#oload_list_cont'),
    $oload_list_body = $('#oload_list_body'),
    $oload_list_other_cont = $('#oload_list_other_cont'),
    $oload_list_other_body = $('#oload_list_other_body'),
    $oload_list_other_name = $('#oload_list_other_name'),
    $oload_list_bodies = $oload_list_body.add($oload_list_other_body),

    $osave_popup = $('#osave_popup'),
    $osave_popup_save = $('#osave_popup_save'),
    $osave_popup_name = $('#osave_popup_name'),
    $osave_popup_close = $('#osave_popup_close'),
    $osave_popup_status = $('#osave_popup_status'),

    $im_mglass_shadow = $('#im_mglass_shadow'),

    $info_expand_open = $('.info_expand_open'),
    $info_expand_close = $('.info_expand_close'),

    //options
    ajax_min_delay = 500,
    ajax_load_min_delay = 500,
    background_stroke_color = '#000',
    canvas_puzzle_bg_overflow = 2,
    canvas_puzzle_zoom_glass_size = 200, //size on canvas
    canvas_puzzle_zoom_glass_osize = 200, //image size
    canvas_puzzle_zoom_glass_src = $im_mglass_shadow.attr('src'),
    canvas_size_h_max = 1900,
    canvas_size_h_min = 600,
    canvas_size_w_max = 3500,
    canvas_size_w_min = parseInt(scss_vars.canvas_size_w_min, 10),
    canvas_size_btn_step = 50,
    canvas_zoom_levels = [
      0.2, 0.3, 0.4, 0.5, 0.6,
      0.7, 0.8, 0.9, 1, 1.1,
      1.25, 1.5, 1.75, 2, 2.5,
      3, 4, 5, 6, 7,
      8
    ],
    enable_oload_osave_history_state = true,
    json_puzzle_size_limit = __JSON_PUZZLE_SIZE_LIMIT__, // eslint-disable-line no-undef
    json_puzzle_pretty = true,
    json_puzzle_pretty_space = '  ',
    pieces_add_sep = 20,
    piece_scale_btn_amount = 0.125,
    piece_scale_max = 5,
    piece_scale_min = 0.3,
    resize_time = 50,
    mail_contact = 'webmaster@puzzle-tool.com',
    url_website = __URL_WEBSITE__, // eslint-disable-line no-undef
    url_saves = 'saves',
    url_pieces_ep = 'pieces/',
    //util
    prot_is_file = window.location.protocol === 'file:',
    decimal_fix = n => Math.round(n * 1e12) / 1e12,
    get_btn_next_scale = (num, lower = false) => {
      let n = num - 1;
      const
        mult = piece_scale_btn_amount,
        rem = decimal_fix(Math.abs(n) % mult);
      if (lower) {
        n = decimal_fix(
          (!rem ? n : (n < 0 ? (-(Math.abs(n) - rem)) : (n - rem + mult))) + 1 - mult
        );
      }
      else {
        n = decimal_fix(
          (!rem ? n : (n < 0 ? -(Math.abs(n) - rem + mult) : (n - rem))) + 1 + mult
        );
      }
      n = Math.max(Math.min(n, piece_scale_max), piece_scale_min);
      return n;
    },
    get_url_pars = () => {
      const pars = {};
      window.location.search.replace(
        /[?&]+([^=&]+)=([^&]*)/gi,
        (all, k, v, offset) => pars[k] = {name: k, value: v, offset}
      );
      return pars;
    },
    hex2rgb = hex => {
      hex = hex.replace('#', '');
      const
        r = parseInt(hex.substring(0, hex.length / 3), 16),
        g = parseInt(hex.substring(hex.length / 3, 2 * hex.length / 3), 16),
        b = parseInt(hex.substring(2 * hex.length / 3, 3 * hex.length / 3), 16);
      return {r, g, b};
    },
    html_e = (t) => {
      const cmap = {'&': '&amp;', '<': '&lt;', '>': '&gt;', '"': '&quot;', '\'': '&#039;'};
      return (t === undefined ? '' : String(t).replace(/[&<>"']/g, (m) => cmap[m]));
    },
    num_format = (n, {decimals = 0, err = '-', force_decimals = true, prefix = '', suffix = '', sign_before_prefix = true, positive_sign = false, negative_sign = true, negative_parenthesis = false} = {}) => {
      let parts, positive, negative, ret;
      if (typeof n !== 'number') { n = parseFloat(n); }
      if (!isFinite(n)) { return err; }
      negative = n < 0;
      positive = n > 0;
      n = Math.abs(n).toFixed(decimals);
      parts = n.split(/\./);
      if (!force_decimals && (!parts[1] || parts[1].match(/^0+$/))) { parts[1] = ''; }
      ret = (negative ? ((negative_sign ? '-' : '') + (negative_parenthesis ? '(' : '')) : (positive && positive_sign ? '+' : ''));
      ret = sign_before_prefix ? (ret + prefix) : (prefix + ret);
      ret += parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',') +
        (parts[1] ? '.' + parts[1] : '') +
        (negative && negative_parenthesis ? ')' : '') +
        suffix;
      return ret;
    },
    cookies_supported = (() => {
      try {
        document.cookie = 'cookietest=1';
        const ret = document.cookie.indexOf('cookietest=') !== -1;
        document.cookie = 'cookietest=1; expires=Thu, 01-Jan-1970 00:00:01 GMT';
        return ret;
      }
      catch (ignore) { return false; }
    })(),
    local_storage_supported = (() => {
      try {
        localStorage.setItem('test', 'test');
        localStorage.removeItem('test');
        return true;
      }
      catch (ignore) { return false; }
    })(),
    is_dev = __IS_DEV__, // eslint-disable-line no-undef
    log_error = e => {
      if (is_dev) { setTimeout(console.error(e), 1); }
    },
    //dom init
    dom_init = () => {
      //mail contact
      $mail_contact.text(mail_contact).attr({href: `mailto:${mail_contact}`});
      //save note
      $edge_compat_note.toggleClass('hidden', !local_storage_supported);
      //pieces load dropzone
      $control_pieces_load.dropzone({
        acceptedFiles: '.png,.jpg,.gif',
        autoQueue: false,
        createImageThumbnails: false,
        dictInvalidFileType: 'Incorrect file type',
        fallback: () => {
          $control_pieces_load.addClass('hidden');
          $control_pieces_load_lbl.addClass('hidden');
          $control_pieces_load_fallback_cont.removeClass('hidden');
        },
        forceFallback: false,
        init: () => {
          $control_pieces_load.removeClass('hidden');
          $control_pieces_load_lbl.removeClass('hidden');
          $control_pieces_load_fallback_cont.addClass('hidden');
          control_pieces_load_dz = Dropzone.forElement($control_pieces_load[0]);
          //file queue added
          control_pieces_load_dz.on('error', (file, e) => file.error = (e || 'Unknown error'));
          control_pieces_load_dz.on('addedfiles', files => {
            files = Array.from(files);
            const error_file = files.find(f => f.error);
            if (error_file) { piece_loading_end({error_file}); }
            else { piece_loading_start(files); }
          });
        },
        previewTemplate: '<span></span>',
        url: './'
      });
      //load json dropzone
      $control_load_json.dropzone({
        //file queue accepted
        acceptedFiles: '.json',
        autoQueue: false,
        createImageThumbnails: false,
        dictInvalidFileType: 'Incorrect file type',
        fallback: () => {
          $control_load_json.addClass('hidden');
          $control_load_json_fallback_cont.removeClass('hidden');
        },
        forceFallback: false,
        init: () => {
          $control_load_json.removeClass('hidden');
          $control_load_json_fallback_cont.addClass('hidden');
          control_load_json_dz = Dropzone.forElement($control_load_json[0]);
          //file queue added
          control_load_json_dz.on('error', (file, e) => file.error = (e || 'Unknown error'));
          control_load_json_dz.on('addedfiles', files => {
            if (files[0] && files[0].status === 'added') { puzzle_load_json(files[0]); }
            else { puzzle_load_json_end({error_file: files[0] || {error: 'No file to load'}}); }
          });
        },
        maxFiles: 1,
        previewTemplate: '<span></span>',
        url: './'
      });
      //dom for file:
      if (prot_is_file) {
        //reset
        $popup_pieces_cont.empty();
        $('.sp-container, .sp-replacer').remove();
        $('.control_color').attr({style: ''});
        $canvas_puzzle.attr({'class': '', style: ''});
        $canvas_cont.append($canvas_puzzle);
        $canvas_cont.find('.canvas-container').remove();
        $piece_load_popup.removeClass('show');
        $oload_popup.removeClass('show');
        $osave_popup.removeClass('show');
        //hide
        $oload_osave_supported.addClass('hidden');
      }
      if (!shows_pieces || !shows_pieces.length) {
        $control_pieces_load_show.addClass('hidden');
      }
      else {
        $control_pieces_load_show.empty();
        shows_pieces.forEach(show => {
          const
            {seasons} = show,
            $select = $('<select />', {'class': 'control_sel'});
          let $all_options = $();
          seasons.forEach((season, seasoni) => {
            const {episodes} = season;
            if (episodes.length) {
              let $options = seasoni ? $('<optgroup />') : $all_options;
              episodes.forEach(episode => {
                if (!episode.pieces.length) { return; }
                $options = $options.add(
                  $('<option />', {value: episode.name}).text(
                    `${season.name}${episode.name === 'All' ? ' ' : ''}${episode.name}
                     (${episode.pieces.length} piece${episode.pieces.length === 1 ? '' : 's'})`
                  ).data({
                    show_name: show.name, season_name: season.name, episode_name: episode.name
                  })
                );
              });
              $all_options = $all_options.add($options);
            }
          });
          if ($all_options.length) {
            if (show.updated) {
              $all_options = $all_options.add($('<optgroup />')).add(
                $('<option />', {value: 'updated', disabled: true}).text(`Updated: ${show.updated}`),
              );
            }
            $control_pieces_load_show.append(
              $('<div />', {'class': 'control_cont'}).append(
                $('<span />', {'class': 'control_lbl'}).text(show.name),
                $select.append(
                  '<option value="select_an_episode">Select an episode</option>',
                  $all_options
                ).change(() => {
                  pieces_add_ep($all_options.filter(':selected').data());
                  $select.val('select_an_episode');
                })
              )
            );
          }
        });
      }
      //canvas size
      const {height, width} = get_default_size();
      $canvas_puzzle.attr({height, width});
      //save
      canvas_puzzle_h = height;
      canvas_puzzle_w = width;
      canvas_resize_controls_update();
      //background color
      $control_canvas_bg.spectrum({
        cancelText: 'Close',
        chooseText: 'Close',
        clickoutFiresChange: true,
        containerClassName: 'ok_is_close',
        hideAfterPaletteSelect: true,
        palette: [
          ['#rgba(255, 255, 255, 0)', '#fff'],
          ['#f1f1f1', '#fdf5e6'],
          ['#fee', '#f00'],
          ['#fff4e5', '#f80'],
          ['#ffc', '#ffd800'],
          ['#efe', '#080'],
          ['#eff', '#00f'],
          ['#fef', '#9400d3'],
          ['#f0f', '#000']
        ],
        preferredFormat: 'rgb',
        replacerClassName: 'my_controls',
        showAlpha: true,
        showButtons: true,
        showInput: true,
        showPalette: true,
        showSelectionPalette: false
      });
      //names color
      $control_pieces_name_color.spectrum({
        cancelText: 'Close',
        chooseText: 'Close',
        clickoutFiresChange: true,
        containerClassName: 'ok_is_close',
        hideAfterPaletteSelect: true,
        palette: [
          ['#fff'], ['#000'], ['#f00'], ['#f80'],
          ['#ffd800'], ['#080'], ['#00f'], ['#9400d3']
        ],
        preferredFormat: 'rgb',
        replacerClassName: 'my_controls',
        showButtons: true,
        showInput: true,
        showPalette: true,
        showSelectionPalette: false
      });
      //names size
      const
        def_name_size = $control_pieces_name_size.data('def'),
        sizes = [8, 10, 12, 14, 16, 18, 20, 22, 24, 26, 28, 30, 32, 34, 36, 38, 40];
      if (sizes.indexOf(def_name_size) < 0) { sizes.push(def_name_size); sizes.sort((a, b) => a - b); }
      $control_pieces_name_size.html(
        sizes.map(v =>
          `<option ${v === def_name_size ? 'selected ' : ''}value="${html_e(v)}">${html_e(v)}</option>`
        )
      );
      //clear/save local_storage_supported
      $control_save_local.toggleClass('hidden', !local_storage_supported);
      $local_storage_supported.toggleClass('lss_enabled', local_storage_supported);
    },
    //resize
    resize_do = function (piece_zoomed_in) {
      //piece load popup
      if ($piece_load_popup.hasClass('show') && $piece_load_popup_cont.hasClass('piece_zoomed_in')) {
        const
          $piece_zoomed_in = $piece_load_popup_cont.find('.piece_cont.piece_zoomed_in'),
          $piece_img_wrap = $piece_zoomed_in.find('.piece_img_wrap'),
          $img = $piece_img_wrap.find('.piece_img_cont img'),
          index = $piece_zoomed_in.data('index'),
          h = piece_loaded_images_add_h[index],
          w = piece_loaded_images_add_w[index];
        //reset
        $img.height('').width('');
        $piece_zoomed_in.css({left: ''});
        //resize
        const img_h = Math.min(h + 2, $piece_img_wrap.outerHeight()) - 2;
        $img.height(img_h).width((img_h / h * w) - 2);
        $piece_zoomed_in.css({left: ($(window).width() - $piece_zoomed_in.outerWidth(true)) / 2});
      }
      else if (piece_zoomed_in) {
        const $piece_conts = $piece_load_popup_cont.find('.piece_cont');
        $piece_conts.css({left: ''}).find('.piece_img_cont img').height('').width('');
      }
    },
    resize_on = function () {
      clearTimeout(resize_timer);
      resize_timer = setTimeout(resize_do, resize_time);
    },
    //status
    status_update = (texts, {error = false, ok = false, clear = false} = {}) => {
      clearTimeout(status_update_timer);
      const
        last_update = $.now(),
        cl = error ? 'red' : (ok ? 'green' : '');
      if (!Array.isArray(texts)) { texts = [texts]; }
      $control_puzzle_status.data({last_update})
        .removeClass('red green').addClass(cl)
        .html(texts.map(t => {
          if (typeof t === 'string') { return html_e(t); }
          else if (t.text) {
            const t_cl = t.error ? 'red' : (t.ok ? 'green' : 'base');
            return `<span class="${t_cl}">${html_e(t.text)}</span>`;
          }
        }).join('<br /><br />'));
      if (clear !== false) {
        status_update_timer = setTimeout(() => {
          if ($control_puzzle_status.data('last_update') === last_update) {
            $control_puzzle_status.text('');
          }
        }, clear);
      }
    },
    //controls
    controls_disable = (canvas_loading = false) => {
      $controls_puzzle.prop({disabled: true});
      $control_pieces_name_color.add($control_canvas_bg).spectrum('disable');
      if (canvas_loading) { $canvas_cont.addClass('loading'); }
    },
    controls_enable = (canvas_loading = false) => {
      $controls_puzzle.prop({disabled: false});
      $control_pieces_name_color.add($control_canvas_bg).spectrum('enable');
      oload_osave_enable_disable();
      if (canvas_loading) { $canvas_cont.removeClass('loading'); }
    },
    update_pan_zoom_controls = () => {
      const
        disabled = (
          canvas_puzzle.viewportTransform.join(',') === '1,0,0,1,0,0' &&
          (!canvas_puzzle_zoom_enabled || canvas_puzzle_zoom_amount === 1) &&
          !$control_wheel_zooms.prop('checked')
        );
      $control_pan_zoom_reset.prop({disabled: disabled});
    },
    reset_pan_zoom_controls = () => {
      canvas_puzzle.setViewportTransform([1, 0, 0, 1, 0, 0]);
      canvas_puzzle.setZoom(1);
      canvas_puzzle.renderAll();
      $control_wheel_zooms.prop({checked: false});
      $controls_puzzle.find('[name="control_canvas_zoom"].control_radio_def').trigger('click');
      update_pan_zoom_controls();
    },
    update_spiece_controls = ({update_disabled_btns = true} = {}) => {
      const
        obj = canvas_selection_last,
        is_multi = obj && obj.type === 'activeSelection';
      if (update_disabled_btns) {
        const
          disabled_rotate_reset = !obj || obj.angle === 0,
          disabled_scale_reset = !obj || obj.scaleX === 1,
          disabled_scale_plus = !obj || obj.scaleX >= piece_scale_max,
          disabled_scale_minus = !obj || obj.scaleX <= piece_scale_min,
          disabled_flip_reset = !obj || (!obj.flipX && !obj.flipY);
        //enable/disable controls
        $control_spiece_rotate_reset.prop({disabled: disabled_rotate_reset});
        $control_spiece_rotate_left.prop({disabled: !obj});
        $control_spiece_rotate_right.prop({disabled: !obj});
        $control_spiece_scale_reset.prop({disabled: disabled_scale_reset});
        $control_spiece_scale_minus.prop({disabled: disabled_scale_minus});
        $control_spiece_scale_plus.prop({disabled: disabled_scale_plus});
        $control_spiece_flip_reset.prop({disabled: disabled_flip_reset});
        $control_spiece_remove.prop({disabled: !obj});
      }
      if (obj) {
        if (is_multi) {
          const selected_pieces = obj.getObjects('group').filter(piece => piece.my_type === 'piece').length;
          $control_spiece_details.text('Select a single piece for details');
          $control_spiece_name.text(`${selected_pieces} pieces`);
        }
        else {
          const
            size_x = num_format(obj.getScaledWidth(), {decimals: 1, force_decimals: false}),
            size_y = num_format(obj.getScaledHeight(), {decimals: 1, force_decimals: false}),
            scale = num_format(obj.scaleX, {decimals: 3, force_decimals: false}),
            angle = num_format(obj.angle, {decimals: 2, force_decimals: false});
          $control_spiece_details.text(`${size_x}x${size_y} @${scale}x ${angle}°`);
          $control_spiece_name.text(obj.getObjects('text')[0].text);
        }
      }
      else {
        $control_spiece_details.text('-');
        $control_spiece_name.text('-');
      }
    },
    canvas_pieces_name_toggle = visible => {
      canvas_pieces.forEach(piece => {
        piece.getObjects('text')[0].set({visible});
      });
      canvas_update_wzoom();
    },
    canvas_pieces_name_color = fill => {
      canvas_pieces.forEach(piece => {
        piece.getObjects('text')[0].set({fill}).setShadow(`0 0 4px ${tinycolor.mostReadable(fill, ['#000', '#fff']).toHexString()}`);
      });
      canvas_update_wzoom();
    },
    canvas_pieces_name_size = fontSize => {
      canvas_pieces.forEach(piece => {
        piece.getObjects('text')[0].set({fontSize});
      });
      canvas_update_wzoom();
    },
    //load/save
    puzzle_vp_reset = () => {
      canvas_puzzle.setViewportTransform([1, 0, 0, 1, 0, 0]);
      canvas_puzzle.setZoom(1);
      canvas_puzzle.renderAll();
      update_pan_zoom_controls();
    },
    puzzle_vp_save_reset = () => {
      const
        prev_zoom = canvas_puzzle.getZoom(),
        prev_transform = canvas_puzzle.viewportTransform;
      puzzle_vp_saved = {prev_zoom, prev_transform};
      puzzle_vp_reset();
    },
    puzzle_vp_restore = () => {
      if (puzzle_vp_saved) {
        const {prev_transform, prev_zoom} = puzzle_vp_saved;
        canvas_puzzle.setViewportTransform(prev_transform);
        canvas_puzzle.setZoom(prev_zoom);
        puzzle_vp_saved = false;
      }
      update_pan_zoom_controls();
    },
    puzzle_json_validate = (json_obj, {src_to_osrc = false} = {}) => {
      let
        src_invalid = false,
        osrc_invalid = false,
        osrc_missing = false,
        json_no_puzzle = false,
        json_over_level = false;
      const
        json_level_max = 2, //0 main, 1 background, piece, 2 piece_img, piece_txt
        valid_my_types = {background: 'rect', piece: 'group', piece_img: 'image', piece_txt: 'text'},
        valid_my_types_main = {background: 'rect', piece: 'group'},
        validate_and_update = (o_in, lvl = 0) => {
          //return false on problem
          if (src_invalid || osrc_invalid || osrc_missing || json_no_puzzle || json_over_level) { return false; }
          if (lvl > json_level_max) { json_over_level = true; return false; }
          if (o_in.objects && Array.isArray(o_in.objects) && o_in.objects.length) {
            //each
            const o_lvl = lvl + 1;
            o_in.objects.forEach((o, i) => {
              //na
              if (!o) { json_no_puzzle = true; return false; }
              //main types
              if (lvl === 0) {
                if (
                  !valid_my_types_main[o.my_type] ||
                  valid_my_types_main[o.my_type] !== o.type || (
                    o.my_type === 'piece' &&
                    (!Array.isArray(o.objects) || o.objects.filter(v => v.type !== 'image').length !== 1 || o.objects.filter(v => v.type !== 'text').length !== 1)
                  ) || (
                    i === 0 && o.my_type !== 'background'
                  )
                ) { json_no_puzzle = true; return false; }
              }
              //types
              else if (
                !valid_my_types[o.my_type] ||
                valid_my_types[o.my_type] !== o.type
              ) { json_no_puzzle = true; return false; }
              //image
              if (o.type === 'image') {
                if (o_lvl !== 2 || o.my_type !== 'piece_img') { json_no_puzzle = true; return false; }
                //validate if present
                if (o.my_osrc && !start_with_url_website(o.my_osrc)) { osrc_invalid = true; return false; }
                //replace src
                if (src_to_osrc) {
                  //invalid if missing
                  if (!o.my_osrc) { osrc_missing = true; return false; }
                  o.src = o.my_osrc;
                }
                //validate source
                else if (
                  !o.src ||
                  (
                    o.src.substr(0, 11) !== 'data:image/' &&
                    !start_with_url_website(o.src)
                  )
                ) { src_invalid = true; return false; }
              }
              //children
              if (validate_and_update(o, o_lvl) === false) { return false; }
            });
          }
          else if (lvl === 0) { json_no_puzzle = true; return false; }
        };
      validate_and_update(json_obj);
      if (src_invalid || osrc_invalid || osrc_missing || json_no_puzzle || json_over_level) { json_obj = {error: 'invalid json'}; }
      return json_obj;
    },
    puzzle_get_json = ({deselect = true, disable_zoom = true, fix = true, online = false} = {}) => {
      let fixed_amount, json_obj;
      //save & reset vp
      puzzle_vp_save_reset();
      //clean
      if (deselect) { canvas_puzzle.discardActiveObject(); }
      if (disable_zoom) { canvas_disable_zoom(); }
      if (fix) { fixed_amount = canvas_pieces_outside_fix({update_wzoom: false}); }
      //validate/osrc->src for online
      if (online) {
        json_obj = canvas_puzzle.toJSON(['my_canvas_h', 'my_canvas_w', 'my_type', 'my_osrc']);
        json_obj = puzzle_json_validate(json_obj, {src_to_osrc: true});
      }
      //toObject_DataUrl for offline
      else {
        fabric.Image.prototype.toObject = fabric.Image.prototype.toObject_DataUrl;
        json_obj = canvas_puzzle.toJSON(['my_canvas_h', 'my_canvas_w', 'my_type', 'my_osrc']);
        fabric.Image.prototype.toObject = fabric.Image.prototype.toObject_Original;
      }
      //restore vp
      puzzle_vp_restore();
      //
      return {fixed_amount, json_obj};
    },
    puzzle_load_from_json = (json, cb) => {
      const valid_my_types_main = {background: 'rect', piece: 'group'};
      let pieces_fail = [];
      if (typeof json === 'string') { json = JSON.parse(json); }
      json = puzzle_json_validate(json);
      //clean
      canvas_puzzle.discardActiveObject();
      canvas_disable_zoom();
      //reset vp
      puzzle_vp_reset();
      //check for error
      if (json.error) {
        if (cb) { cb({err: json.error}); }
        return;
      }
      //load
      return canvas_puzzle.loadFromJSON(json, () => {
        const items = canvas_puzzle.getObjects();
        //bg/size get
        canvas_puzzle_bg = items.find(item => item.my_type === 'background');
        const
          height = canvas_puzzle_bg.my_canvas_h || canvas_puzzle_bg.height,
          width = canvas_puzzle_bg.my_canvas_w || canvas_puzzle_bg.width;
        canvas_puzzle_bg.set({
          height: height + canvas_puzzle_bg_overflow * 2,
          hoverCursor: 'default',
          left: -canvas_puzzle_bg_overflow,
          my_canvas_h: height,
          my_canvas_w: width,
          selectable: false,
          stroke: background_stroke_color,
          top: -canvas_puzzle_bg_overflow,
          width: width + canvas_puzzle_bg_overflow * 2
        });
        canvas_puzzle.backgroundColor = canvas_puzzle_bg.fill;
        //pieces get
        canvas_pieces = items.filter(item => item.my_type === 'piece');
        //pieces_fail
        pieces_fail.forEach(o => {
          canvas_puzzle.remove(o);
          canvas_pieces = canvas_pieces.filter(piece => piece !== o);
        });
        //canvas resize/set controls
        canvas_resize_do({height, width}, {cb: (pars = {}) => {
          pars.pieces_fail = pieces_fail.length;
          pars.pieces_total = canvas_pieces.length + pars.pieces_fail;
          if (cb) { cb(pars); }
        }});
        //update controls
        $control_canvas_bg.spectrum('set', canvas_puzzle_bg.fill).trigger('change', [canvas_puzzle_bg.fill]);
        if (canvas_pieces.length) {
          const piece_text = canvas_pieces[0].getObjects().find(item => item.my_type === 'piece_txt');
          if (piece_text) {
            $control_pieces_name_toggle.prop({checked: piece_text.visible});
            $control_pieces_name_color.spectrum('set', piece_text.fill);
            $control_pieces_name_size.val(piece_text.fontSize);
          }
        }
      }, (src, o) => {
        if (
          !o || !valid_my_types_main[o.my_type] ||
          valid_my_types_main[o.my_type] !== o.type || (
            o.my_type === 'piece' &&
            (!o.getObjects('image').length || !o.getObjects('text').length)
          )
        ) { pieces_fail.push(o); }
      });
    },
    puzzle_load_new = (cb) => {
      const
        height = canvas_puzzle_h,
        width = canvas_puzzle_w;
      //reset vp
      puzzle_vp_reset();
      //clear
      canvas_puzzle.remove(...canvas_puzzle.getObjects());
      canvas_pieces = [];
      //bg
      canvas_puzzle_bg = new fabric.Rect({
        height: height + canvas_puzzle_bg_overflow * 2,
        hoverCursor: 'default',
        fill: $control_canvas_bg.val(),
        left: -canvas_puzzle_bg_overflow,
        my_canvas_h: height,
        my_canvas_w: width,
        my_type: 'background',
        selectable: false,
        stroke: background_stroke_color,
        top: -canvas_puzzle_bg_overflow,
        width: width + canvas_puzzle_bg_overflow * 2
      });
      canvas_puzzle.backgroundColor = canvas_puzzle_bg.fill;
      canvas_puzzle.add(canvas_puzzle_bg);
      //update canvas/controls
      canvas_resize_do(undefined, {cb});
    },
    puzzle_load_local_or_new = ({error = false} = {}) => {
      let
        saved_puzzle,
        error_local = true;
      const
        ready_cb = ({pieces_fail = 0, pieces_total = 0} = {}) => {
          let msgs = [
            {text: (saved_puzzle && !error_local ? 'Browser saved ready' : 'Ready')}
          ];
          if (error) {
            if (Array.isArray(error)) { msgs = error.concat(msgs); }
            else { msgs.unshift(error); }
          }
          else {
            if (pieces_fail) {
              msgs.push({text: `${pieces_fail} out of ${pieces_total} piece${pieces_total === 1 ? '' : 's'} could not be loaded`});
            }
          }
          status_update(msgs, {error, clear: error ? 10000 : 6000});
          controls_enable();
        },
        new_cb = () => puzzle_load_new(ready_cb);
      controls_disable();
      try {
        //saved
        if (local_storage_supported && (saved_puzzle = localStorage.getItem('saved_puzzle'))) {
          //load
          status_update('Loading...');
          puzzle_load_from_json(saved_puzzle, (pars) => {
            error_local = false;
            ready_cb(pars);
          });
        }
        //new
        else {
          new_cb();
        }
      }
      catch (e) {
        const nerror = `Something went wrong loading ${saved_puzzle ? 'the previous' : 'an empty'} puzzle, try using the latest version of Chrome`;
        error = error || [];
        if (!Array.isArray(error)) { error = [error]; }
        error.push(nerror);
        new_cb();
        log_error(e);
      }
    },
    puzzle_load_online_url = (name, is_short_url = false) => {
      controls_disable(true);
      const puzzle = `${is_short_url ? '' : 'online '}puzzle`;
      status_update(`Loading ${puzzle} '${name}'...`);
      let
        prev_json_obj,
        owner = false;
      const
        end_cb = ({err = false, pieces_fail = 0, pieces_total = 0} = {}) => {
          //error
          if (err) {
            const msgs = [`Error loading ${puzzle} '${name}'`, `(${err})`];
            //reset to prev
            if (prev_json_obj && prev_json_obj.objects.length) {
              status_update(msgs, {error: true, clear: 10000});
              puzzle_load_from_json(prev_json_obj, () => controls_enable(true));
            }
            //local/new
            else { puzzle_load_local_or_new({error: msgs}); }
          }
          //ok
          else {
            if (is_short_url) { oload_url_set(name); }
            else { oload_osave_set(name, owner); }
            const msgs = [`Loaded ${puzzle} '${name}'`];
            if (pieces_fail) {
              msgs.push({text: `${pieces_fail} out of ${pieces_total} piece${pieces_total === 1 ? '' : 's'} could not be loaded`});
            }
            status_update(msgs, {ok: true, clear: 6000});
            controls_enable(true);
          }
        },
        ajax_error = err => end_cb({err: err || 'ajax error'}),
        ajax_ok = json => {
          try {
            //save
            prev_json_obj = puzzle_get_json({fix: false}).json_obj;
            //load new
            puzzle_load_from_json(json, end_cb);
          }
          catch (e) {
            end_cb({err: 'Invalid JSON'});
            log_error(e);
          }
        },
        st = $.now();
      $.ajax({
        data: is_short_url ? {json_id: name} : {puzzle_load: 1, name},
        dataType: 'json',
        error: (jqxhr, status_text) => ajax_error(jqxhr.status === 404 ? 'puzzle not found' : status_text),
        success: o => {
          setTimeout(() => {
            if (is_short_url) { ajax_ok(o); }
            else {
              if (o.owner) { owner = true; }
              if (o.json) { ajax_ok(o.json); }
              else { ajax_error(o.error || 'data error'); }
            }
          }, Math.max(0, ajax_load_min_delay - ($.now() - st)));
        },
        type: 'get',
        url: url_saves
      });
    },
    puzzle_load_json_end = ({error_file = false, error = '', prev_json_obj = false, pieces_fail = 0, pieces_total = 0} = {}) => {
      //error
      if (error_file) {
        if (error) { error_file.error = error; }
        const msgs = [`Error loading JSON: ${error_file.name ? `'${error_file.name}'` : ''}`];
        if (error_file.error) { msgs.push(`(${error_file.error})`); }
        else { msgs.push('Try using the latest version of Chrome'); }
        //reset to prev
        if (prev_json_obj && prev_json_obj.objects.length) {
          status_update(msgs, {error: true, clear: 10000});
          puzzle_load_from_json(prev_json_obj);
        }
        //local/new
        else { puzzle_load_local_or_new({error: msgs}); }
      }
      //ok
      else {
        const msgs = ['JSON file loaded'];
        if (pieces_fail) {
          msgs.push({text: `${pieces_fail} out of ${pieces_total} piece${pieces_total === 1 ? '' : 's'} could not be loaded`});
        }
        status_update(msgs, {ok: true, clear: 6000});
      }
      //reset
      $control_load_json_fallback.val('');
      if (control_load_json_dz) { control_load_json_dz.removeAllFiles(true); }
      controls_enable();
    },
    puzzle_load_json = file => {
      controls_disable();
      let prev_json_obj;
      try {
        status_update('Loading JSON file...');
        //reader
        const reader = new FileReader();
        reader.onload = re => {
          try {
            //parse
            const parsed_json = JSON.parse(re.target.result);
            //save
            prev_json_obj = puzzle_get_json({fix: false}).json_obj;
            //load
            puzzle_load_from_json(parsed_json, pars => {
              if (pars.err) {
                pars.error_file = file;
                pars.error = pars.err;
                delete pars.err;
              }
              puzzle_load_json_end(pars);
            });
          }
          catch (e) {
            puzzle_load_json_end({error_file: file, error: 'Invalid JSON', prev_json_obj});
            log_error(e);
          }
        };
        reader.onerror = () => { puzzle_load_json_end({error_file: file, error: 'Error reading file', prev_json_obj}); };
        reader.readAsText(file);
      }
      catch (e) {
        puzzle_load_json_end({error_file: file, prev_json_obj});
        log_error(e);
      }
    },
    puzzle_load = () => {
      //from url
      if (url_pars.puzzle) { puzzle_load_online_url(url_pars.puzzle.value); }
      else if (url_pars.p) { puzzle_load_online_url(url_pars.p.value, true); }
      //saved/new
      else { puzzle_load_local_or_new(); }
    },
    puzzle_save_img = () => {
      try {
        const
          date = new Date(),
          date_name = [
            date.getFullYear(), date.getMonth() + 1, date.getDate(), date.getHours(), date.getMinutes()
          ].map((v, i) => `${i ? (i === 3 ? ' ' : (i > 2 ? '' : '-')) : ''}${String(v).padStart(2, '0')}`).join(''),
          image_name = `my puzzle ${date_name}.png`;
        //save & reset vp
        puzzle_vp_save_reset();
        //clean
        canvas_puzzle.discardActiveObject();
        canvas_disable_zoom();
        canvas_pieces_outside_fix({update_wzoom: false});
        canvas_puzzle_bg.stroke = null;
        //get save
        const
          img_bin = atob(canvas_puzzle.toDataURL().split(/,/)[1]),
          byte_arr = new Uint8Array([...img_bin].map(v => v.charCodeAt(0))),
          blob = new Blob([byte_arr], {type: 'image/png'});
        //restore stroke
        canvas_puzzle_bg.stroke = background_stroke_color;
        //restore vp
        puzzle_vp_restore();
        //ie
        if (window.navigator.msSaveBlob) {
          window.navigator.msSaveOrOpenBlob(blob, image_name);
        }
        //others
        else {
          control_save_img_link.href = window.URL.createObjectURL(blob, {type: 'image/png'});
          control_save_img_link.download = image_name;
          document.body.appendChild(control_save_img_link);
          control_save_img_link.click();
          document.body.removeChild(control_save_img_link);
        }
        //
        status_update(
          `Image downloading as '${image_name}'`,
          {ok: true, clear: 6000}
        );
      }
      catch (e) {
        status_update(
          'Something went wrong downloading the image, try the latest version of Chrome',
          {error: true, clear: 12000}
        );
        //restore vp
        puzzle_vp_restore();
        log_error(e);
      }
    },
    puzzle_save_local = (show_status = true) => {
      if (!local_storage_supported) {
        status_update(
          'Your browser does not support localStorage', {error: true, clear: 3000}
        );
        return;
      }
      try {
        const {fixed_amount, json_obj} = puzzle_get_json();
        localStorage.setItem('saved_puzzle', JSON.stringify(json_obj, null, json_puzzle_pretty ? json_puzzle_pretty_space : null));
        if (show_status) {
          const msgs = [
            'Puzzle saved to browser'
          ];
          if (fixed_amount) {
            msgs.push(
              `${fixed_amount} piece${fixed_amount === 1 ? '' : 's'} w${fixed_amount === 1 ? 'as' : 'ere'} moved from outside the canvas`
            );
          }
          status_update(
            msgs, {ok: true, clear: 6000 + (fixed_amount ? 5000 : 0)}
          );
        }
        return true;
      }
      catch (e) {
        if (show_status) {
          status_update(
            'Something went wrong saving to browser, you can save as JSON or try the latest version of Chrome',
            {error: true, clear: 12000}
          );
        }
        log_error(e);
        return false;
      }
    },
    puzzle_save_json = () => {
      try {
        const
          {fixed_amount, json_obj} = puzzle_get_json(),
          json_str = JSON.stringify(json_obj, null, json_puzzle_pretty ? json_puzzle_pretty_space : null),
          blob = new Blob([json_str], {type: 'application/json'}),
          date = new Date(),
          date_name = [
            date.getFullYear(), date.getMonth() + 1, date.getDate(), date.getHours(), date.getMinutes()
          ].map((v, i) => `${i ? (i === 3 ? ' ' : (i > 2 ? '' : '-')) : ''}${String(v).padStart(2, '0')}`).join('');
        //ie
        if (window.navigator.msSaveBlob) {
          window.navigator.msSaveOrOpenBlob(blob, `my puzzle ${date_name}.json`);
        }
        //others
        else {
          control_save_json_link.href = window.URL.createObjectURL(blob, {type: 'application/json'});
          control_save_json_link.download = `my puzzle ${date_name}.json`;
          document.body.appendChild(control_save_json_link);
          control_save_json_link.click();
          document.body.removeChild(control_save_json_link);
        }
        //
        const msgs = ['Puzzle saved to JSON'];
        if (fixed_amount) {
          msgs.push(
            `${fixed_amount} piece${fixed_amount === 1 ? '' : 's'} w${fixed_amount === 1 ? 'as' : 'ere'} moved from outside the canvas`
          );
        }
        status_update(
          msgs, {ok: true, clear: 6000 + (fixed_amount ? 5000 : 0)}
        );
      }
      catch (e) {
        status_update(
          'Something went wrong saving to JSON, try the latest version of Chrome',
          {error: true, clear: 10000}
        );
        log_error(e);
      }
    },
    puzzle_save_local_clear = () => {
      const do_error = t => status_update(t, {error: true, clear: 10000});
      if (confirm('Do you want to delete all the pieces?')) {
        try {
          //clean
          canvas_puzzle.discardActiveObject();
          canvas_disable_zoom();
          //clear
          canvas_puzzle.remove(...canvas_pieces);
          canvas_pieces = [];
          //remove
          if (local_storage_supported) { localStorage.removeItem('saved_puzzle'); }
          status_update('Everything cleared', {ok: true, clear: 3000});
          //reset controls
          const
            def_color_canvas = $control_canvas_bg.data('def'),
            def_color_pieces_name = $control_pieces_name_color.data('def');
          $control_canvas_bg.spectrum('set', def_color_canvas).trigger('change', [def_color_canvas]);
          $control_pieces_name_color.spectrum('set', def_color_pieces_name).trigger('change', [def_color_pieces_name]);
          $control_pieces_name_size.val($control_pieces_name_size.data('def')).trigger('change');
          //resize
          canvas_resize_do(get_default_size());
        }
        catch (e) {
          do_error('Something went wrong clearing the canvas');
          log_error(e);
        }
        //no online save name
        oload_osave_reset();
      }
    },
    puzzle_save_online_name = (name, dialog) => {
      if (dialog) { osave_popup_controls_disable(); }
      const
        end_cb = (err) => {
          //error
          if (err) {
            if (dialog) {
              osave_popup_save_status(`Error saving (${err})`, {err: true});
              osave_popup_controls_enable();
            }
            else {
              const msgs = [`Error saving online puzzle '${name}'`, `(${err})`];
              status_update(msgs, {error: true, clear: 10000});
            }
          }
          //ok
          else {
            oload_osave_set(name, true);
            if (dialog) {
              osave_popup_save_status('Puzzle saved', {ok: true});
              setTimeout(osave_popup_close, 1000);
            }
            status_update(`Saved online puzzle '${name}'`, {ok: true, clear: 6000});
          }
        },
        ajax_error = err => {
          setTimeout(() => end_cb(err), Math.max(0, ajax_min_delay - ($.now() - st)));
        },
        ajax_ok = () => {
          setTimeout(() => end_cb(), Math.max(0, ajax_min_delay - ($.now() - st)));
        },
        st = $.now();
      if (name.match(/^[a-z\d_]{4,20}$/)) {
        if (dialog) { osave_popup_save_status('Puzzle saving...'); }
        else { status_update(`Saving online puzzle '${name}'`); }
        const {json_obj} = puzzle_get_json({online: true});
        if (!json_obj.error) {
          const json = JSON.stringify(json_obj);
          if (json.length <= json_puzzle_size_limit) {
            $.ajax({
              data: {json},
              dataType: 'json',
              error: () => ajax_error('ajax error'),
              success: o => {
                if ((oload_osave_key || o.key) && o.puzzle_name) {
                  //(re)save cookie
                  if (oload_osave_key) { oload_osave_key_save(oload_osave_key); }
                  else { oload_osave_key_save(o.key); }
                  ajax_ok();
                }
                else { ajax_error(o.error || 'data error'); }
              },
              type: 'post',
              url: url_saves + (url_saves.match(/\?/) ? '&' : '?') + $.param({puzzle_save: 1, name})
            });
          }
          else { end_cb('save is too large'); }
        }
        else { end_cb(json_obj.error); }
      }
      else { end_cb('bad name'); }
    },
    //canvas events
    update_piece_rotate_scale = (piece = canvas_piece_selected) => {
      const angle_mult = piece.flipX ^ piece.flipY ? -1 : 1;
      piece.getObjects('text')[0].set({
        flipX: piece.flipX, flipY: piece.flipY,
        scaleX: 1 / piece.scaleX, scaleY: 1 / piece.scaleY
      }).rotate(-piece.angle * angle_mult);
    },
    update_group_rotate_scale = (group = canvas_selection_last) => {
      const pieces = group.getObjects('group').filter(piece => piece.my_type === 'piece');
      pieces.forEach(piece => {
        const
          flipX = !!(piece.flipX ^ group.flipX),
          flipY = !!(piece.flipY ^ group.flipY),
          angle_mult = flipX ^ flipY ? -1 : 1;
        piece.getObjects('text')[0].set({
          flipX, flipY,
          scaleX: 1 / piece.scaleX / group.scaleX, scaleY: 1 / piece.scaleY / group.scaleY
        }).rotate(-(group.angle + piece.angle) * angle_mult);
      });
    },
    update_rotate_scale = (obj = canvas_selection_last, {out_fix = true, render = false, update_disabled_btns = true} = {}) => {
      if (obj) {
        const is_multi = obj.type === 'activeSelection';
        if (is_multi) { update_group_rotate_scale(obj); }
        else { update_piece_rotate_scale(obj); }
        update_spiece_controls({update_disabled_btns});
        //fix outside piece
        if (out_fix && canvas_piece_selected) { canvas_piece_outside_fix(); }
      }
      if (render) { canvas_puzzle.renderAll(); }
    },
    canvas_object_rotated_scaled = () => {
      update_rotate_scale(undefined, {update_disabled_btns: false});
    },
    canvas_object_rotating_scaling = () => {
      update_rotate_scale(undefined, {update_disabled_btns: false, out_fix: false});
    },
    canvas_object_modified = () => {
      update_spiece_controls();
    },
    canvas_selection_change = () => {
      const
        active = canvas_puzzle.getActiveObject(),
        is_multi = active && active.type === 'activeSelection',
        is_piece = active && active.my_type === 'piece';
      canvas_piece_selected = false;
      if (active) {
        if (canvas_pieces.indexOf(active) > -1) {
          canvas_piece_selected = active;
          //leave piece on top even after deselecting it
          canvas_puzzle.bringToFront(canvas_piece_selected);
        }
      }
      //if multi selection deselected, fix pieces outside
      if (
        canvas_selection_last && canvas_selection_last.type === 'activeSelection' &&
        (!active || canvas_pieces.indexOf(active) > -1)
      ) { canvas_pieces_outside_fix(); }
      //save last
      canvas_selection_last = (is_multi || is_piece) ? active : false;
      //update spiece controls
      update_spiece_controls();
    },
    canvas_keep_obj_inside = (obj, {set_coords = false} = {}) => {
      obj.setCoords();
      const
        bounds = obj.getBoundingRect(true),
        half_height = bounds.height / 2,
        half_width = bounds.width / 2,
        set = {};
      if (bounds.left < -half_width) {
        set.left = Math.max(obj.left, obj.left - (bounds.left + half_width));
      }
      else if (bounds.left + bounds.width > canvas_puzzle_w + half_width) {
        set.left = Math.min(obj.left, canvas_puzzle_w - bounds.width + obj.left - bounds.left + half_width);
      }
      if (bounds.top < -half_height) {
        set.top = Math.max(obj.top, obj.top - (bounds.top + half_height));
      }
      else if (bounds.top + bounds.height > canvas_puzzle_h + half_height) {
        set.top = Math.min(obj.top, canvas_puzzle_h - bounds.height + obj.top - bounds.top + half_height);
      }
      if (Object.keys(set).length) {
        obj.set(set);
        if (set_coords) { obj.setCoords(); }
      }
    },
    canvas_h_w_change = ({e, h = 0, w = 0}) => {
      const
        height = Math.min(canvas_size_h_max, Math.max(canvas_size_h_min,
          canvas_puzzle_h + h
        )),
        width = Math.min(canvas_size_w_max, Math.max(canvas_size_w_min,
          canvas_puzzle_w + w
        ));
      if (height !== canvas_puzzle_h || width !== canvas_puzzle_w) {
        $control_canvas_size_btns.prop({disabled: true});
        canvas_resize_do({height, width}, {cb: () => $(e.currentTarget).focus()});
      }
    },
    //canvas helpers
    canvas_piece_outside_fix_helper = (piece, {height, width} = {}) => {
      //fixed
      let fixed = false;
      //move piece if outside canvas
      const
        bounds = piece.getBoundingRect(true),
        half_height = bounds.height / 2,
        half_width = bounds.width / 2,
        fix = {};
      if (bounds.left + half_width < 0) { fix.left = 0; }
      else if (bounds.left + half_width > width) { fix.left = width - bounds.width; }
      if (bounds.top + half_height < 0) { fix.top = 0; }
      else if (bounds.top + half_height > height) { fix.top = height - bounds.height; }
      if (Object.keys(fix).length) {
        //difference between bound and left/top
        if (fix.hasOwnProperty('left')) { fix.left += piece.left - bounds.left; }
        if (fix.hasOwnProperty('top')) { fix.top += piece.top - bounds.top; }
        //move
        piece.set(fix).setCoords();
        //mark as fixed
        fixed = true;
      }
      //return fixed
      return fixed;
    },
    canvas_piece_outside_fix = (piece = canvas_piece_selected, {height = canvas_puzzle_h, update_wzoom = false, width = canvas_puzzle_w} = {}) => {
      //fixed
      let fixed = false;
      //piece exists, fix it
      if (piece && canvas_piece_outside_fix_helper(piece, {height, width})) { fixed = true; }
      //
      if (fixed) {
        if (update_wzoom) { canvas_update_wzoom(); }
        status_update('1 piece was moved from outside the canvas', {clear: 5000});
      }
      //return fixed
      return fixed;
    },
    canvas_pieces_outside_fix = ({height = canvas_puzzle_h, update_wzoom = true, width = canvas_puzzle_w} = {}) => {
      //already fixing
      if (canvas_pieces_outside_fixing) { return 0; }
      //mark as fixing
      canvas_pieces_outside_fixing = true;
      //amount
      let fixed_amount = 0;
      //deselect
      canvas_puzzle.discardActiveObject();
      //move pieces that end up outside canvas
      canvas_pieces.forEach(piece => {
        //fix if needed
        if (canvas_piece_outside_fix_helper(piece, {height, width})) {
          //bring to front
          canvas_puzzle.bringToFront(piece);
          //count
          fixed_amount++;
        }
      });
      if (fixed_amount) {
        if (update_wzoom) { canvas_update_wzoom(); }
        status_update(
          `${fixed_amount} piece${fixed_amount === 1 ? '' : 's'} w${fixed_amount === 1 ? 'as' : 'ere'} moved from outside the canvas`,
          {clear: 5000}
        );
      }
      //mark as not fixing
      canvas_pieces_outside_fixing = false;
      //return amount
      return fixed_amount;
    },
    canvas_zoom_src_set = () => {
      puzzle_vp_save_reset();
      canvas_puzzle_zoom_src = canvas_puzzle.toDataURL();
      puzzle_vp_restore();
    },
    canvas_update_wzoom = ({render = true, canvas_resize = false, canvas_zoom = false, cb = false} = {}) => {
      if (canvas_puzzle_zoom_enabled && canvas_puzzle_zoom_el) {
        canvas_puzzle_zoom_el.visible = false;
        canvas_puzzle.renderAll();
        canvas_zoom_src_set();
        canvas_puzzle_zoom_img.setSrc(canvas_puzzle_zoom_src, () => {
          if (canvas_puzzle_zoom_el) { canvas_puzzle_zoom_el.visible = true; }
          if (canvas_resize || canvas_zoom) { canvas_zoom_repos(); }
          else { canvas_puzzle.renderAll(); }
          if (cb) { cb(); }
        });
      }
      else {
        canvas_zoom_src_set();
        if (render) { canvas_puzzle.renderAll(); }
        if (cb) { cb(); }
      }
    },
    start_with_url_website = (t) => {
      return url_website.some(url_ws => t.substr(0, url_ws.length) === url_ws);
    },
    //canvas resize
    get_default_size = () => {
      const
        def_canvas_size = $control_canvas_size.data('def'),
        [width, height] = def_canvas_size.split(/x/).map(parseFloat);
      return {height, width};
    },
    canvas_resize_controls_update = ({height = canvas_puzzle_h, width = canvas_puzzle_w} = {}) => {
      $control_canvas_size.text(`${width}x${height}`);
      $control_canvas_h_minus.prop({disabled: height <= canvas_size_h_min});
      $control_canvas_h_plus.prop({disabled: height >= canvas_size_h_max});
      $control_canvas_w_minus.prop({disabled: width <= canvas_size_w_min});
      $control_canvas_w_plus.prop({disabled: width >= canvas_size_w_max});
    },
    canvas_resize_do = (size = get_default_size(), {set_controls = true, cb = false} = {}) => {
      const
        old_w = canvas_puzzle.getWidth(),
        old_h = canvas_puzzle.getHeight(),
        {height, width} = size;
      //resize
      canvas_puzzle.setDimensions({height, width});
      //background
      canvas_puzzle_bg.set({
        height: height + canvas_puzzle_bg_overflow * 2,
        my_canvas_h: height,
        my_canvas_w: width,
        width: width + canvas_puzzle_bg_overflow * 2
      });
      //save
      canvas_puzzle_h = height;
      canvas_puzzle_w = width;
      //fix pieces if changing size
      if (old_w !== width || old_h !== height) {
        canvas_pieces_outside_fix({update_wzoom: false});
      }
      //set controls
      if (set_controls) {
        canvas_resize_controls_update();
        canvas_selection_change();
      }
      //
      canvas_update_wzoom({canvas_resize: true, cb});
    },
    //canvas zoom
    canvas_pan_fix = ({
      new_x = canvas_puzzle.viewportTransform[4],
      new_y = canvas_puzzle.viewportTransform[5],
      set_it = false, zoom = canvas_puzzle.getZoom()
    } = {}) => {
      const
        distance_x = 200,
        distance_y = 200;
      if (new_x <= -(canvas_puzzle_w - distance_x) * zoom) { new_x = -(canvas_puzzle_w - distance_x) * zoom; }
      else if (new_x >= canvas_puzzle_w - distance_x * zoom) { new_x = canvas_puzzle_w - distance_x * zoom; }
      if (new_y <= -(canvas_puzzle_h - distance_y) * zoom) { new_y = -(canvas_puzzle_h - distance_y) * zoom; }
      else if (new_y >= canvas_puzzle_h - distance_y * zoom) { new_y = canvas_puzzle_h - distance_y * zoom; }
      if (set_it) {
        canvas_puzzle.viewportTransform[4] = new_x;
        canvas_puzzle.viewportTransform[5] = new_y;
      }
      return {new_x, new_y};
    },
    canvas_zoom_data = (pos, type) => {
      if (['move', 'create', 'repos'].indexOf(type) === -1) { type = 'move'; }
      const
        mouse_left = pos.x,
        mouse_top = pos.y,
        canvas_half_h = canvas_puzzle_h / 2,
        canvas_half_w = canvas_puzzle_w / 2,
        grp_left = mouse_left - mouse_left * canvas_puzzle_zoom_amount,
        grp_top = mouse_top - mouse_top * canvas_puzzle_zoom_amount,
        glass_left = mouse_left - canvas_half_w - canvas_puzzle_zoom_glass_size / 2 / canvas_puzzle_zoom_amount,
        glass_top = mouse_top - canvas_half_h - canvas_puzzle_zoom_glass_size / 2 / canvas_puzzle_zoom_amount,
        clipTo = ctx => {
          ctx.arc(
            mouse_left - canvas_half_w, mouse_top - canvas_half_h,
            canvas_puzzle_zoom_glass_size / canvas_puzzle_zoom_amount / 2, 0, Math.PI * 2, true
          );
        };
      //move
      if (type === 'move') {
        const
          props_el = {clipTo, left: grp_left, top: grp_top},
          props_glass = {left: glass_left, top: glass_top};
        return {props_el, props_glass};
      }
      //create/repos
      else {
        const
          canvas_puzzle_zoom_glass_scale = canvas_puzzle_zoom_glass_size / canvas_puzzle_zoom_glass_osize / canvas_puzzle_zoom_amount,
          props_el = {
            clipTo,
            height: canvas_puzzle_h,
            left: grp_left - canvas_puzzle.viewportTransform[4],
            scaleX: canvas_puzzle_zoom_amount,
            scaleY: canvas_puzzle_zoom_amount,
            top: grp_top,
            width: canvas_puzzle_w
          },
          props_glass = {
            left: glass_left,
            scaleX: canvas_puzzle_zoom_glass_scale,
            scaleY: canvas_puzzle_zoom_glass_scale,
            top: glass_top
          },
          props_img = {
            left: -canvas_half_w,
            top: -canvas_half_h
          };
        if (type === 'create') { props_el.objectCaching = false; }
        return {props_el, props_glass, props_img};
      }
    },
    canvas_zoom_repos = () => {
      const {props_el, props_glass, props_img} = canvas_zoom_data(canvas_puzzle_zoom_last_pos, 'repos');
      canvas_puzzle_zoom_el.set(props_el);
      canvas_puzzle_zoom_glass.set(props_glass);
      canvas_puzzle_zoom_img.set(props_img);
      canvas_puzzle.renderAll();
    },
    canvas_cursors_selectable_update = () => {
      const
        zoom_or_alt = canvas_puzzle_zoom_enabled || canvas_puzzle_alt_down,
        get_cursor = (default_cursor = '') => {
          return (canvas_puzzle_pan_enabled ? 'grabbing' : (
            canvas_puzzle_alt_down ? 'grab' : (
              canvas_puzzle_zoom_enabled ? 'default' : default_cursor
            )
          ));
        };
      //change cursors
      canvas_puzzle.defaultCursor = get_cursor('default');
      canvas_puzzle.hoverCursor = get_cursor('move');
      canvas_puzzle_bg.hoverCursor = get_cursor('default');
      //enable/disable selection
      canvas_puzzle.selection = !zoom_or_alt;
      //make pieces (not) selectable
      const hoverCursor = get_cursor('');
      canvas_puzzle.forEachObject(item => {
        if (item.my_type === 'piece') {
          item.set({hoverCursor, selectable: !zoom_or_alt});
        }
      });
    },
    canvas_disable_alt = () => {
      canvas_puzzle_alt_down = false;
      canvas_cursors_selectable_update();
    },
    canvas_enable_alt = () => {
      canvas_puzzle_alt_down = true;
      //deselect
      canvas_puzzle.discardActiveObject();
      //
      canvas_cursors_selectable_update();
    },
    canvas_disable_zoom = () => {
      //controls
      $controls_puzzle.find('[name="control_canvas_zoom"].control_radio_def').prop({checked: true});
      //disable if enabled
      if (canvas_puzzle_zoom_enabled) {
        //disable
        canvas_puzzle_zoom_enabled = false;
        //
        update_pan_zoom_controls();
        //
        canvas_cursors_selectable_update();
        //remove element
        canvas_puzzle.setOverlayImage(null);
        canvas_puzzle_zoom_el = false;
      }
      //render
      canvas_puzzle.renderAll();
    },
    canvas_enable_zoom = (amount) => {
      //repos enabled
      if (canvas_puzzle_zoom_enabled) {
        if (amount !== canvas_puzzle_zoom_amount) {
          canvas_puzzle_zoom_amount = amount;
          canvas_update_wzoom({canvas_zoom: true});
        }
      }
      else {
        //deselect
        canvas_puzzle.discardActiveObject();
        //save src
        canvas_zoom_src_set();
        //enable
        canvas_puzzle_zoom_amount = amount;
        canvas_puzzle_zoom_enabled = true;
        //
        update_pan_zoom_controls();
        //
        canvas_cursors_selectable_update();
        //render
        canvas_puzzle.renderAll();
      }
    },
    //pieces load
    pieces_create_add_list = (pieces_data, show_name, cb) => {
      const
        pieces_fn = pieces_data.map(piece => piece.file_name),
        ajax_error = () => {
          pieces_fn.forEach((piece_fn, i) => cb(null, i));
        };
      $.ajax({
        data: {pieces_fn, show_name},
        dataType: 'json',
        error: ajax_error,
        success: o => {
          if (o.pieces && o.pieces.length) {
            o.pieces.forEach((opiece, i) => {
              if (opiece.error) { cb(null, i); }
              else {
                const {angle, name, scale} = pieces_data[i];
                fabric.util.loadImage(opiece.base64, opiece_img => {
                  if (opiece_img) {
                    //run it with image element
                    piece_create_add({
                      angle, h: opiece_img.height, i, img: opiece_img,
                      my_osrc: opiece.osrc, name, scale, w: opiece_img.width
                    }, cb);
                  }
                  else { cb(null, i); }
                });
              }
            });
          }
          else { ajax_error(); }
        },
        type: 'post',
        url: `${url_pieces_ep}?get_pieces=1`
      });
    },
    piece_create_add = ({
      angle = 0, h, i, img, my_osrc = false,
      name, scale = 1, textc = $control_pieces_name_color.val(), w
    } = {}, cb) => {
      const
        image = new fabric.Image(img, Object.assign(
          {my_type: 'piece_img'},
          my_osrc ? {my_osrc} : {}
        )),
        text = new fabric.Text(name, {
          fill: textc,
          left: w / 2,
          my_type: 'piece_txt',
          fontSize: $control_pieces_name_size.val(),
          originX: 'center',
          originY: 'center',
          scaleX: 1 / scale,
          scaleY: 1 / scale,
          top: h / 2,
          visible: $control_pieces_name_toggle.is(':checked')
        }),
        distance = pieces_add_sep * (i + 1),
        piece = new fabric.Group([image, text], {
          hoverCursor: canvas_puzzle_zoom_enabled ? 'default' : '',
          left: distance,
          my_type: 'piece',
          scaleX: scale,
          scaleY: scale,
          selectable: !canvas_puzzle_zoom_enabled,
          top: distance
        });
      //rotate
      if (angle) {
        piece.rotate(angle);
        text.rotate(-angle);
      }
      //text shadow
      text.setShadow(`0 0 4px ${tinycolor.mostReadable(textc, ['#000', '#fff']).toHexString()}`);
      //add
      canvas_pieces.push(piece);
      canvas_puzzle.add(piece);
      //cb
      if (cb) { cb(piece, i); }
      //
      return piece;
    },
    pieces_add_done = ({added = [], select_sort = false} = {}) => {
      //select_sort
      if (select_sort && added.length) {
        added.sort((a, b) => a.i - b.i);
        added.forEach(item => {
          if (item.piece) { canvas_puzzle.bringToFront(item.piece); }
        });
      }
      //get last and amounts
      const
        items = added.filter(item => item.piece),
        last_piece = (items[items.length - 1] || {}).piece,
        total = {ok: items.length, error: added.length - items.length};
      //fix outside
      const fixed_amount = canvas_pieces_outside_fix({update_wzoom: false});
      //zoom/select
      if (canvas_puzzle_zoom_enabled) { canvas_update_wzoom(); }
      else if (last_piece) { canvas_puzzle.setActiveObject(last_piece); }
      //status
      controls_enable();
      const msgs = [`${total.ok} piece${total.ok !== 1 ? 's' : ''} added`];
      if (total.error) {
        msgs.push(`${total.error} piece${total.error !== 1 ? 's' : ''} could not be loaded`);
      }
      if (fixed_amount) {
        msgs.push(
          `${fixed_amount} piece${fixed_amount === 1 ? '' : 's'} w${fixed_amount === 1 ? 'as' : 'ere'} moved from outside the canvas`
        );
      }
      status_update(msgs, {error: total.error, ok: !total.error, clear: 5000 + (fixed_amount ? 5000 : 0) + (total.error ? 4000 : 0)});
    },
    pieces_add_ep = ({show_name, season_name, episode_name} = {}) => {
      const showf = shows_pieces.find(show => show.name === show_name);
      if (showf) {
        const seasonf = showf.seasons.find(season => season.name === season_name);
        if (seasonf) {
          const episodef = seasonf.episodes.find(episode => episode.name === episode_name);
          if (episodef) {
            const
              added = [],
              {pieces} = episodef,
              piece_create_complete = () => {
                //done
                pieces_add_done({added, select_sort: true});
              },
              piece_create_cb = (piece, i) => {
                added.push({piece, i});
                status_update(`${added.length}/${pieces.length} piece${pieces.length !== 1 ? 's' : ''} added`);
                if (added.length === pieces.length) { piece_create_complete(); }
              };
            //status
            controls_disable();
            status_update(`Loading ${pieces.length} piece${pieces.length !== 1 ? 's' : ''}`);
            //pieces
            pieces_create_add_list(pieces, show_name, piece_create_cb);
          }
        }
      }
    },
    pieces_add_popup = () => {
      const
        added = [],
        pieces = $popup_pieces_cont.find('.piece_img_cb:checked').closest('.piece_cont').get();
      //status
      controls_disable();
      status_update(`Loading ${pieces.length} piece${pieces.length !== 1 ? 's' : ''}`);
      //pieces
      pieces.forEach((piece_cont, i) => {
        const
          $piece_cont = $(piece_cont),
          index = $piece_cont.data('index'),
          img = piece_loaded_images_add[index],
          h = piece_loaded_images_add_h[index],
          w = piece_loaded_images_add_w[index],
          textc = $control_pieces_name_color.val(),
          piece = piece_create_add({
            h, i, img,
            name: piece_loaded_images_add_name[index], textc, w
          });
        added.push({piece, i});
      });
      //done
      pieces_add_done({added});
    },
    image_to_canvas = (img, w, h) => {
      const
        canvas = document.createElement('canvas'),
        ctx = canvas.getContext('2d');
      canvas.height = h; canvas.width = w;
      ctx.clearRect(0, 0, w, h);
      ctx.drawImage(img, 0, 0, w, h);
      return {canvas, ctx};
    },
    image_to_data_url = (img, w = img.width, h = img.height) => {
      return image_to_canvas(img, w, h).canvas.toDataURL();
    },
    image_remove_colors = (img, w, h, colors, tolerances, cb) => {
      //filter
      let colors_tolerances = [];
      colors.forEach((color, i) => colors_tolerances.push(JSON.stringify([color, tolerances[i]])));
      colors_tolerances = colors_tolerances.filter((v, i, arr) => arr.indexOf(v) === i);
      colors_tolerances = colors_tolerances.map(v => JSON.parse(v));
      //init canvas
      const
        {canvas, ctx} = image_to_canvas(img, w, h);
      //make pixels transparent
      const
        imgd = ctx.getImageData(0, 0, w, h),
        pix = imgd.data,
        len = pix.length;
      colors_tolerances.forEach(c_t => {
        const
          col = hex2rgb(c_t[0]),
          t = Math.round(c_t[1] / 100 * 255);
        let i;
        for (i = 0; i < len; i += 4) {
          if (
            pix[i + 3] &&
            Math.abs(pix[i] - col.r) <= t &&
            Math.abs(pix[i + 1] - col.g) <= t &&
            Math.abs(pix[i + 2] - col.b) <= t
          ) { pix[i + 3] = 0; }
        }
      });
      ctx.putImageData(imgd, 0, 0);
      //crop
      const
        bounds = {t: null, l: null, r: null, b: null};
      let i, x, y;
      for (i = 0; i < len; i += 4) {
        if (pix[i + 3]) {
          x = (i / 4) % w;
          y = Math.floor((i / 4) / w);
          if (bounds.t === null) { bounds.t = y; }
          if (bounds.l === null || x < bounds.l) { bounds.l = x; }
          if (bounds.r === null || bounds.r < x) { bounds.r = x; }
          if (bounds.b === null || bounds.b < y) { bounds.b = y; }
        }
      }
      const
        h_n = bounds.b - bounds.t + 1,
        w_n = bounds.r - bounds.l + 1,
        imgd_n = ctx.getImageData(bounds.l, bounds.t, w_n, h_n);
      canvas.width = w_n; canvas.height = h_n;
      ctx.putImageData(imgd_n, 0, 0);
      //get new image
      const img_n = new Image();
      $(img_n).one('load', () => cb(img_n));
      img_n.src = canvas.toDataURL('image/png');
    },
    piece_loading_popup_update = (img, $img, img_n, h, w, index) => {
      //update and resize if new image
      if (img !== img_n) {
        const
          $img_n = $(img_n),
          img_loaded = () => {
            const h_n = img_n.height, w_n = img_n.width;
            //replace
            $img.replaceWith($img_n.css({height: '', width: ''}));
            //if different size
            if (w_n !== w || h_n !== h) {
              piece_loaded_images_add_h[index] = h_n;
              piece_loaded_images_add_w[index] = w_n;
              resize_do();
            }
          };
        //already loaded
        if ($img_n.prop('complete')) { img_loaded(); }
        //wait for image to load
        else { $img_n.one('load', img_loaded); }
      }
    },
    piece_loading_popup_reset = $piece_cont => {
      const
        index = $piece_cont.data('index'),
        $img = $piece_cont.find('.piece_img_cont img'),
        img = piece_loaded_images_add[index],
        h = piece_loaded_images_add_h[index],
        w = piece_loaded_images_add_w[index],
        img_n = piece_loaded_images[index];
      //store
      piece_loaded_images_add[index] = img_n;
      //update
      piece_loading_popup_update(img, $img, img_n, h, w, index);
    },
    piece_loading_popup_recolor = $piece_cont => {
      const
        index = $piece_cont.data('index'),
        $img = $piece_cont.find('.piece_img_cont img'),
        img = piece_loaded_images[index],
        h = piece_loaded_images_h[index],
        w = piece_loaded_images_w[index],
        $transp_colors = $piece_cont.find('.piece_trans_cb:checked').closest('.transp_color'),
        colors = $.map($transp_colors.find('.control_color'), item => item.value),
        tolerances = $.map($transp_colors.find('.control_range'), item => item.value),
        end_cb = (img_n) => {
          //store
          piece_loaded_images_add[index] = img_n;
          //update
          piece_loading_popup_update(img, $img, img_n, h, w, index);
        };
      if (!$transp_colors.length) { end_cb(img); }
      else {
        image_remove_colors(
          img, piece_loaded_images_w[index], piece_loaded_images_h[index],
          colors, tolerances, end_cb
        );
      }
    },
    piece_loading_popup = () => {
      $popup_pieces_cont.empty();
      const
        $transp_color = $('<div />', {'class': 'transp_color'}).append(
          $('<label />', {'class': '', title: 'Make color transparent'}).append(
            $('<span />', {'class': 'control_lblc'}).text('Transp.'),
            $('<input />', {'class': 'piece_trans_cb', type: 'checkbox'})
          ),
          $('<input />', {'class': 'control_color', type: 'text', value: '#ff00ff'}),
          $('<div />', {'class': 'control_lblc'}).text('Tolerance'),
          $('<input />', {'class': 'control_range', max: 99, min: 0, type: 'range', value: 0})
        ),
        $piece_cont_temp = $('<div />', {'class': 'piece_cont'}).append(
          $('<label />', {'class': 'piece_img_wrap'}).append(
            $('<input />', {'class': 'piece_img_cb', type: 'checkbox', checked: 'checked'}),
            $('<span />', {'class': 'piece_img_cont'})
          ),
          $('<div />', {'class': 'control_cont piece_zoom_cont'}).append(
            $('<button />', {'class': 'control_btn piece_zoom', type: 'button'})
          ),
          $('<div />', {'class': 'control_cont piece_name_cont'}).append(
            $('<div />', {'class': 'control_lblc'}).text('Name'),
            $('<input />', {'class': 'control_inp piece_name', type: 'text'})
          ),
          $('<div />', {'class': 'control_cont transp_colors'}).append(
            $transp_color.clone(),
            $transp_color.clone().find('.control_color').val('#00ff00').end(),
            $transp_color.clone().find('.control_color').val('#0000ff').end()
          )
        );
      piece_loaded_images.forEach((img, index) => {
        img.alt = img.alt || '';
        const
          $piece_cont = $piece_cont_temp.clone(),
          $control_colors = $piece_cont.find('.control_color');
        $piece_cont.attr({'data-index': index});
        $piece_cont.find('.piece_img_cont').append(img);
        $piece_cont.find('.piece_name').val(piece_loaded_images_add_name[index]);
        $control_colors.spectrum({
          clickoutFiresChange: true,
          hideAfterPaletteSelect: true,
          palette: [
            ['#f0f'], ['#0f0'], ['#000'], ['#f00'], ['#00f']
          ],
          preferredFormat: 'hex',
          replacerClassName: 'my_popup',
          showButtons: true,
          showInput: true,
          showPalette: true,
          showSelectionPalette: false
        });
        $popup_pieces_cont.append($piece_cont);
      });
      $piece_load_popup_load.prop({disabled: !piece_loaded_images.length});
      $piece_load_popup.addClass('show');
    },
    piece_loading_popup_close = () => {
      $piece_load_popup.removeClass('show');
      $popup_pieces_cont.find('.transp_color .control_color').spectrum('destroy');
    },
    piece_loading_end = ({error_file = false} = {}) => {
      //error
      if (error_file) {
        const msgs = [`Error reading image: ${error_file.name || ''}`];
        if (error_file.error) { msgs.push(`(${error_file.error})`); }
        status_update(msgs, {error: true, clear: 10000});
      }
      //ok, popup
      else { piece_loading_popup(); }
      //enable/reset
      piece_loading_block = false;
      $control_pieces_load_fallback.val('');
      if (control_pieces_load_dz) { control_pieces_load_dz.removeAllFiles(true); }
    },
    piece_loading_start = files => {
      if (piece_loading_block) { return; }
      status_update('Loading pieces...');
      //block
      piece_loading_block = true;
      //
      piece_loaded_images = [];
      piece_loaded_images_h = [];
      piece_loaded_images_w = [];
      piece_loaded_images_add = [];
      piece_loaded_images_add_h = [];
      piece_loaded_images_add_w = [];
      piece_loaded_images_add_name = [];
      //process
      let any_error = false;
      const
        do_error = error_file => {
          piece_loading_popup_close(); //ie fix
          any_error = true;
          piece_loading_end({error_file});
        };
      files.forEach((file, index) => {
        if (any_error) { return; }
        //reader
        const reader = new FileReader();
        reader.onload = (re) => {
          if (any_error) { return; }
          //load img
          const img = new Image();
          img.onload = () => {
            //save
            piece_loaded_images[index] = img;
            piece_loaded_images_h[index] = img.height;
            piece_loaded_images_w[index] = img.width;
            piece_loaded_images_add[index] = img;
            piece_loaded_images_add_h[index] = img.height;
            piece_loaded_images_add_w[index] = img.width;
            piece_loaded_images_add_name[index] = (file.name || '').replace(/^(.*)\..*/, '$1');
            //incomplete?
            if (piece_loaded_images.filter(v => v).length === files.length) {
              piece_loading_end();
            }
          };
          img.onerror = () => {
            if (any_error) { return; }
            do_error(file);
          };
          img.src = re.target.result;
        };
        reader.onerror = () => {
          if (any_error) { return; }
          do_error(file);
        };
        reader.readAsDataURL(file);
      });
    },
    //online
    oload_osave_enable_disable = () => {
      osave_enable_disable();
      oload_enable_disable();
    },
    oload_enable_disable = () => {
      $control_load_online_disabled.toggleClass('hidden', cookies_supported);
      if (!cookies_supported) {
        $control_load_online.prop({disabled: true});
        $control_save_online.prop({disabled: true});
        $control_save_online_disabled.addClass('hidden');
      }
    },
    osave_enable_disable = () => {
      let enabled;
      try {
        enabled = canvas_pieces.every(piece => {
          const osrc = piece.getObjects('image')[0].my_osrc;
          //osrc and starts with url_website
          return (osrc && start_with_url_website(osrc));
        });
      }
      catch (ignore) { enabled = false; }
      $control_save_online.prop({disabled: !enabled});
      $control_save_online_disabled.toggleClass('hidden', enabled);
    },
    oload_osave_url_update = (name = false, value = '') => {
      if (enable_oload_osave_history_state) {
        try {
          let
            url_clean = false,
            url_change = false,
            url_pars_tmp = get_url_pars();
          //clean facebook fbclid
          if (url_pars_tmp.fbclid) {
            delete url_pars_tmp.fbclid;
            url_clean = true;
          }
          //update/add
          if (name) {
            if (url_pars_tmp[name]) {
              if (url_pars_tmp[name].value !== value) {
                url_pars_tmp[name].value = value;
                url_change = true;
              }
            }
            else {
              url_pars_tmp[name] = {name, value, offset: Infinity};
              url_change = true;
            }
          }
          //remove p/puzzle
          ['p', 'puzzle'].forEach(aname => {
            if (name !== aname && url_pars_tmp[aname]) {
              delete url_pars_tmp[aname];
              url_change = true;
            }
          });
          if (url_change || url_clean) {
            //new url
            const
              url_pars_tmp_vals = Object.values(url_pars_tmp),
              new_search = !url_pars_tmp_vals.length ? '' : `?${url_pars_tmp_vals.sort((a, b) => (a.offset - b.offset)).map(o => `${o.name}=${o.value}`).join('&')}`,
              {search} = window.location;
            //update url
            if (new_search !== search) {
              const
                state_action = url_change ? 'pushState' : 'replaceState',
                {protocol, hostname, port, pathname, hash} = window.location,
                loc = `${protocol}//${hostname}${port ? `:${port}` : ''}${pathname}${new_search}${hash}`;
              window.history[state_action]({}, '', loc);
            }
          }
        }
        catch (ignore) { }
        url_pars = get_url_pars();
      }
    },
    oload_url_set = name => {
      oload_osave_name = false;
      oload_osave_owner = false;
      oload_osave_url_update('p', name);
    },
    oload_osave_set = (name, owner) => {
      oload_osave_name = name;
      oload_osave_owner = owner;
      oload_osave_url_update('puzzle', name);
    },
    oload_osave_reset = () => {
      oload_osave_name = false;
      oload_osave_owner = false;
      //
      oload_osave_enable_disable();
      oload_osave_url_update();
    },
    oload_osave_key_get = () => {
      oload_osave_key = $.cookie('oload_osave_key') || '';
      $oload_popup_key_input.val(oload_osave_key);
      return oload_osave_key;
    },
    oload_osave_key_save = (key) => {
      oload_osave_key = key;
      $.cookie('oload_osave_key', oload_osave_key, {expires: 365 * 10});
      $oload_popup_key_input.val(oload_osave_key);
    },
    //online save
    osave_popup = () => {
      osave_popup_controls_enable();
      $osave_popup_name.val('');
      osave_popup_save_status();
      $osave_popup.addClass('show');
      $osave_popup_name.focus();
    },
    osave_popup_save_status = (t = '', {err = false, ok = false} = {}) => {
      clearTimeout($osave_popup_status.data('timeout'));
      $osave_popup_status.text(t).stop(true)
        .css({opacity: 1}).removeClass('green red')
        .addClass(err ? 'red' : (ok ? 'green' : ''));
      if (t) {
        $osave_popup_status.data({
          timeout: setTimeout(() => $osave_popup_status.text(''), err ? 4000 : 3000)
        });
      }
    },
    osave_popup_controls_disable = () => {
      $osave_popup_name.prop({readonly: true});
      $osave_popup_save.prop({disabled: true});
      $osave_popup_close.prop({disabled: true});
    },
    osave_popup_controls_enable = () => {
      $osave_popup_name.prop({readonly: false});
      $osave_popup_save.prop({disabled: false});
      $osave_popup_close.prop({disabled: false});
    },
    osave_popup_save = () => {
      puzzle_save_online_name($osave_popup_name.val(), true);
    },
    osave_popup_close = () => {
      $osave_popup.removeClass('show');
    },
    puzzle_save_online = () => {
      if (
        oload_osave_name && oload_osave_owner &&
        confirm(`Do you want to save the current puzzle as '${oload_osave_name}'?`)
      ) {
        puzzle_save_online_name(oload_osave_name, false);
      }
      else { osave_popup(); }
    },
    //online load
    oload_popup_controls_disable = () => {
      $oload_popup_close.prop({disabled: true});
      $oload_popup_load_key_input.prop({readonly: true});
      $oload_popup_load_key.prop({disabled: true});
      $oload_list_bodies.find('.oload_popup_load, .oload_popup_remove').prop({disabled: true});
    },
    oload_popup_controls_enable = () => {
      $oload_popup_close.prop({disabled: false});
      $oload_popup_load_key_input.prop({readonly: false});
      $oload_popup_load_key.prop({disabled: false});
      $oload_list_bodies.find('.oload_popup_load, .oload_popup_remove').prop({disabled: false});
    },
    oload_popup = () => {
      oload_popup_controls_disable();
      $oload_popup_load_key_input.val('');
      oload_popup_load_key_status();
      $oload_loading.removeClass('hidden');
      $oload_list_cont.addClass('hidden');
      $oload_list_other_cont.addClass('hidden');
      let
        show_other_list = false;
      const
        end_cb = () => {
          oload_popup_controls_enable();
          $oload_loading.addClass('hidden');
          $oload_list_cont.removeClass('hidden');
          $oload_list_other_cont.toggleClass('hidden', !show_other_list);
        },
        list_add_in = (list, $tbody, yours) => {
          let
            $rows = '';
          if (!list.length) {
            $rows += `<tr><td colspan="${yours ? 5 : 4}">
              ${html_e('You don\'t have any puzzle saved online right now.')}
            </td></tr>`;
          }
          list.forEach(item => {
            if (Array.isArray(item)) {
              $rows += `<tr><td colspan="${yours ? 5 : 4}">${html_e(item[0])}</td></tr>`;
            }
            else {
              const
                d = new Date(item.cdate * 1000), //dates from utc, get converted to local time
                cl = ['puzzle'];
              $rows += `<tr data-name="${html_e(item.name)}" class="${cl.join(' ')}">
                <td>${html_e(item.name)}</td>
                <td class="link_cont"><a class="link" href="?puzzle=${html_e(item.name)}" target="_blank">link</a></td>
                <td class="date">${html_e(d.toLocaleDateString() + ' ' + d.toLocaleTimeString())}</td>
                ${!yours ? '' : `
                <td class="remove">
                  <button class="control_btn oload_popup_remove" type="button">Remove</button>
                </td>
                `}
                <td class="load">
                  <button class="control_btn oload_popup_load" type="button">Load</button>
                </td>
              </tr>`;
            }
          });
          $tbody.html($rows);
        },
        list_add = (list, other_list) => {
          //list
          list_add_in(list, $oload_list_body, list.length);
          //other
          if (other_list && other_list.name && Array.isArray(other_list.list)) {
            show_other_list = true;
            $oload_list_other_name.text(other_list.name);
            list_add_in(other_list.list, $oload_list_other_body, !list.length);
          }
          //
          end_cb();
        },
        ajax_error = (err) => {
          setTimeout(() => {
            list_add([[`Error loading list, close and try again (${err || 'unknown error'})`]]);
          }, Math.max(0, ajax_min_delay - ($.now() - st)));
        },
        st = $.now();
      $.ajax({
        data: {puzzle_list: 1},
        dataType: 'json',
        error: () => ajax_error('ajax error'),
        success: o => {
          if ((oload_osave_key || o.key) && Array.isArray(o.list) && o.list.every(item => item.name && item.cdate)) {
            //(re)save cookie
            if (oload_osave_key) { oload_osave_key_save(oload_osave_key); }
            else { oload_osave_key_save(o.key); }
            //
            setTimeout(() => list_add(o.list, o.other_list), Math.max(0, ajax_min_delay - ($.now() - st)));
          }
          else { ajax_error(o.error || 'data/key error'); }
        },
        type: 'get',
        url: url_saves
      });
      $oload_popup.addClass('show');
    },
    oload_popup_load = (e) => {
      oload_popup_controls_disable();
      const
        $btn = $(e.currentTarget),
        $row = $btn.closest('tr.puzzle'),
        name = $row.data('name');
      oload_popup_close();
      puzzle_load_online_url(name);
    },
    oload_popup_remove = (e) => {
      oload_popup_controls_disable();
      let st;
      const
        $btn = $(e.currentTarget),
        $row = $btn.closest('tr.puzzle'),
        name = $row.data('name'),
        end_cb = (err, reload = true, clear_name = !err) => {
          //error
          if (err) { alert(`Error removing puzzle (${err})`); }
          //ok
          else {
            //no online save name
            if (clear_name && name === oload_osave_name) { oload_osave_reset(); }
          }
          //reload list
          if (reload) { oload_popup(); }
          else { oload_popup_controls_enable(); }
        },
        ajax_error = err => {
          setTimeout(() => end_cb(err), Math.max(0, ajax_min_delay - ($.now() - st)));
        },
        ajax_ok = () => {
          setTimeout(() => end_cb(), Math.max(0, ajax_min_delay - ($.now() - st)));
        };
      if (confirm('Are you sure you want to PERMANENTLY remove this online puzzle?')) {
        st = $.now();
        $row.addClass('removing');
        $.ajax({
          data: {puzzle_remove: 1, name},
          dataType: 'json',
          error: () => ajax_error('ajax error'),
          success: o => {
            if (o.removed || o.already_removed) { ajax_ok(); }
            else { ajax_error(o.error || 'data error'); }
          },
          type: 'get',
          url: url_saves
        });
      }
      else { end_cb(false, false, false); }
    },
    oload_popup_close = () => {
      $oload_popup.removeClass('show');
    },
    oload_popup_load_key_status = (t = '', {err = false, ok = false} = {}) => {
      clearTimeout($oload_popup_load_key_status.data('timeout'));
      $oload_popup_load_key_status.text(t).stop(true)
        .css({opacity: 1}).removeClass('green red')
        .addClass(err ? 'red' : (ok ? 'green' : ''));
      if (t) {
        $oload_popup_load_key_status.data({
          timeout: setTimeout(() => $oload_popup_load_key_status.text(''), err ? 4000 : 3000)
        });
      }
    },
    oload_popup_load_key = () => {
      oload_popup_controls_disable();
      const
        key = $oload_popup_load_key_input.val(),
        end_cb = (err, reload = true) => {
          //error
          if (err) {
            oload_popup_load_key_status(`Invalid key${err === true ? '' : ` (${err})`}`, {err: true});
          }
          //ok
          else {
            oload_popup_load_key_status('Key updated', {ok: true});
            $oload_popup_load_key_input.val('');
          }
          //reload list/reset controls
          if (reload) { oload_popup(); }
          else { oload_popup_controls_enable(); }
        },
        ajax_error = err => {
          setTimeout(() => end_cb(err, false), Math.max(0, ajax_min_delay - ($.now() - st)));
        },
        ajax_ok = () => {
          setTimeout(() => {
            oload_osave_key_save(key);
            end_cb();
          }, Math.max(0, ajax_min_delay - ($.now() - st)));
        },
        st = $.now();
      if (key.match(/^[a-zA-Z\d]{12,16}$/)) {
        oload_popup_load_key_status('Validating key...');
        $.ajax({
          data: {key_exists: 1, key},
          dataType: 'json',
          error: () => ajax_error('ajax error'),
          success: o => {
            if (o.ok) { ajax_ok(); }
            else { ajax_error(o.error || 'data error'); }
          },
          type: 'get',
          url: url_saves
        });
      }
      else { end_cb(true, false); }
    },
    puzzle_load_online = () => {
      oload_popup();
    };
  let
    //fabric canvas
    canvas_puzzle,
    //canvas status
    canvas_pieces_outside_fixing,
    canvas_puzzle_h,
    canvas_puzzle_w,
    canvas_puzzle_zoom_enabled,
    canvas_puzzle_zoom_amount,
    canvas_puzzle_pan_enabled,
    canvas_puzzle_pan_x,
    canvas_puzzle_pan_y,
    canvas_puzzle_alt_down,
    //canvas items
    canvas_piece_selected = false,
    canvas_pieces = [],
    canvas_puzzle_bg,
    canvas_puzzle_zoom_el,
    canvas_puzzle_zoom_img,
    canvas_puzzle_zoom_glass,
    canvas_selection_last, //multi, piece, null/false/undefined
    //canvas zoom data
    canvas_puzzle_zoom_src,
    canvas_puzzle_zoom_last_pos,
    //pieces load status
    piece_loading_block = false,
    //pieces load data
    piece_loaded_images = [],
    piece_loaded_images_h = [],
    piece_loaded_images_w = [],
    piece_loaded_images_add,
    piece_loaded_images_add_h,
    piece_loaded_images_add_w,
    piece_loaded_images_add_name,
    //online save/load
    oload_osave_key = oload_osave_key_get(),
    oload_osave_name = false,
    oload_osave_owner = false,
    puzzle_vp_saved = false,
    //
    url_pars = get_url_pars(),
    //dropzones
    control_load_json_dz,
    control_pieces_load_dz,
    //status update timer
    status_update_timer,
    //resize
    resize_timer;

  //fabric defaults
  fabric.Group.prototype.cornerSize = 12;
  fabric.Group.prototype.cornerStyle = 'circle';
  fabric.Group.prototype.lockScalingX = false;
  fabric.Group.prototype.lockScalingY = false;
  fabric.Group.prototype.lockUniScaling = true;
  fabric.Group.prototype.rotatingPointOffset = 12;
  fabric.Group.prototype.transparentCorners = false;
  fabric.Group.prototype._controlsVisibility = {
    bl: true, br: true, mb: false, ml: false, mr: false, mt: false,
    tl: true, tr: true, mtr: true
  };
  fabric.Text.prototype.fontFamily = 'sans-serif';
  fabric.Text.prototype.fontWeight = 700;
  //fabric toObject extras
  fabric.Image.prototype.toObject_Original = fabric.Image.prototype.toObject;
  fabric.Image.prototype.toObject_DataUrl = function (propertiesToInclude) {
    return fabric.util.object.extend(
      fabric.Image.prototype.toObject_Original.call(this, propertiesToInclude),
      {src: image_to_data_url(this.getElement())}
    );
  };
  fabric.Rect.prototype.toObject_Original = fabric.Rect.prototype.toObject;
  fabric.Rect.prototype.toObject_NoStroke = function (propertiesToInclude) {
    const obj = fabric.Rect.prototype.toObject_Original.call(this, propertiesToInclude);
    if (obj.my_type === 'background') { delete obj.stroke; }
    return obj;
  };
  fabric.Rect.prototype.toObject = fabric.Rect.prototype.toObject_NoStroke;

  fabric.Canvas.prototype.toJSON_Original = fabric.Canvas.prototype.toJSON;
  fabric.Canvas.prototype.toJSON_NoBg = function (propertiesToInclude) {
    const obj = fabric.Canvas.prototype.toJSON_Original.call(this, propertiesToInclude);
    delete obj.background;
    return obj;
  };
  fabric.Canvas.prototype.toJSON = fabric.Canvas.prototype.toJSON_NoBg;
  //dom init
  dom_init();
  //canvas create
  canvas_puzzle = new fabric.Canvas($canvas_puzzle[0], {backgroundColor: $control_canvas_bg.val(), includeDefaultValues: false});
  //puzzle_load
  puzzle_load();

  //canvas events
  //update scale control
  canvas_puzzle.on('object:modified', canvas_object_modified);
  //prevent pieces moving outside canvas
  canvas_puzzle.on('object:moving', e => {
    canvas_keep_obj_inside(e.target);
  });
  //object scaled rotated
  canvas_puzzle.on('object:rotated', canvas_object_rotated_scaled);
  canvas_puzzle.on('object:rotating', canvas_object_rotating_scaling);
  canvas_puzzle.on('object:scaled', canvas_object_rotated_scaled);
  canvas_puzzle.on('object:scaling', canvas_object_rotating_scaling);
  //selection change
  canvas_puzzle.on('selection:cleared', canvas_selection_change);
  canvas_puzzle.on('selection:updated', canvas_selection_change);
  canvas_puzzle.on('selection:created', canvas_selection_change);
  //zoom
  canvas_puzzle.on('mouse:wheel', function(opt) {
    if ($control_wheel_zooms.prop('checked')) {
      const delta = -opt.e.deltaY;
      let
        zoom = canvas_puzzle.getZoom(),
        zoom_pos = canvas_zoom_levels.indexOf(zoom);
      if (zoom_pos < 0) { zoom_pos = canvas_zoom_levels.indexOf(1); }
      zoom_pos = Math.max(0, Math.min(canvas_zoom_levels.length - 1, zoom_pos + (delta > 0 ? 1 : -1)));
      zoom = canvas_zoom_levels[zoom_pos];
      canvas_puzzle.zoomToPoint({x: opt.e.offsetX, y: opt.e.offsetY}, zoom);
      //limit, canvas inside viewport
      canvas_pan_fix({set_it: true, zoom});
      update_pan_zoom_controls();
      opt.e.preventDefault();
      opt.e.stopPropagation();
    }
  });
  //pan/magnifying glass
  canvas_puzzle.on('mouse:down', opt => {
    if (opt.e.altKey) {
      canvas_puzzle_pan_enabled = true;
      canvas_puzzle_pan_x = opt.pointer.x;
      canvas_puzzle_pan_y = opt.pointer.y;
      canvas_cursors_selectable_update();
    }
  });
  canvas_puzzle.on('mouse:up', () => {
    if (canvas_puzzle_pan_enabled) {
      canvas_puzzle_pan_enabled = false;
      canvas_cursors_selectable_update();
      update_pan_zoom_controls();
      canvas_puzzle.forEachObject(item => item.setCoords());
    }
  });
  canvas_puzzle.on('mouse:move', opt => {
    if (canvas_puzzle_pan_enabled) {
      let
        new_x = canvas_puzzle.viewportTransform[4] + opt.pointer.x - canvas_puzzle_pan_x,
        new_y = canvas_puzzle.viewportTransform[5] + opt.pointer.y - canvas_puzzle_pan_y;
      //limit, canvas inside viewport
      ({new_x, new_y} = canvas_pan_fix({new_x, new_y}));
      //move
      canvas_puzzle.viewportTransform[4] = new_x;
      canvas_puzzle.viewportTransform[5] = new_y;
      canvas_puzzle.requestRenderAll();
      canvas_puzzle_pan_x = opt.pointer.x;
      canvas_puzzle_pan_y = opt.pointer.y;
    }
    else if (canvas_puzzle_zoom_enabled) {
      //save for repos
      canvas_puzzle_zoom_last_pos = {x: opt.pointer.x, y: opt.pointer.y};
      //move it if object
      if (canvas_puzzle_zoom_el) {
        if (canvas_puzzle_zoom_el !== true) {
          const {props_el, props_glass} = canvas_zoom_data(opt.absolutePointer, 'move');
          canvas_puzzle_zoom_el.set(props_el);
          canvas_puzzle_zoom_glass.set(props_glass);
          canvas_puzzle.renderAll();
        }
      }
      //add image
      else {
        //avoid setting it up again
        canvas_puzzle_zoom_el = true;
        //set it up
        canvas_puzzle_zoom_img = new fabric.Image(null, {backgroundColor: '#ffffff'});
        canvas_puzzle_zoom_glass = new fabric.Image();
        canvas_puzzle_zoom_img.setSrc(canvas_puzzle_zoom_src, () => {
          canvas_puzzle_zoom_glass.setSrc(canvas_puzzle_zoom_glass_src, () => {
            const {props_el, props_glass, props_img} = canvas_zoom_data(opt.absolutePointer, 'create');
            canvas_puzzle_zoom_el = new fabric.Group(
              [canvas_puzzle_zoom_img, canvas_puzzle_zoom_glass], props_el
            );
            canvas_puzzle_zoom_glass.set(props_glass);
            canvas_puzzle_zoom_img.set(props_img);
            canvas_puzzle.setOverlayImage(canvas_puzzle_zoom_el);
            canvas_puzzle.renderAll();
          });
        });
      }
    }
  });

  //dom events
  $('body').keydown(e => {
    if (e.which === 18) {
      if (!canvas_puzzle_alt_down) {
        canvas_enable_alt();
        e.preventDefault();
      }
    }
    else if (e.which === 82) {
      if (
        !$controls_puzzle.prop('disabled') &&
        !$control_pan_zoom_reset.prop('disabled') &&
        !$(e.target).is(':text, textarea, select')
      ) {
        $control_pan_zoom_reset.click();
      }
    }
  });
  $('body').keyup(e => {
    if (e.which === 18) {
      if (canvas_puzzle_alt_down) {
        canvas_disable_alt();
      }
    }
  });
  $canvas_cont.on('keydown', e => {
    if (canvas_selection_last) {
      if (e.which >= 37 && e.which <= 40) {
        e.preventDefault();
        const
          obj = canvas_selection_last,
          mult = e.shiftKey ? 10 : 1;
        if (e.ctrlKey) {
          //rotate
          if (e.which === 37 || e.which === 39) {
            obj.rotate(obj.angle + 0.25 * mult * (e.which === 37 ? -1 : 1));
          }
          //scale
          else {
            const
              scale_add = 1 / Math.min(obj.width, obj.height),
              scale = Math.min(piece_scale_max, Math.max(piece_scale_min,
                obj.scaleX + scale_add * mult * (e.which === 38 ? -1 : 1)
              ));
            obj.set({scaleX: scale, scaleY: scale});
          }
        }
        //move
        else {
          if (e.which === 37) { obj.left -= 1 * mult; }
          else if (e.which === 38) { obj.top -= 1 * mult; }
          else if (e.which === 39) { obj.left += 1 * mult; }
          else if (e.which === 40) { obj.top += 1 * mult; }
        }
        canvas_keep_obj_inside(obj, {set_coords: true});
        update_rotate_scale(obj, {render: true, update_disabled_btns: false});
      }
    }
  });
  $canvas_cont.on('keyup', e => {
    if (canvas_selection_last) {
      if (e.which >= 37 && e.which <= 40) {
        update_spiece_controls();
      }
    }
  });
  //
  $control_canvas_bg.on('change move.spectrum', (e, color) => {
    canvas_puzzle_bg.set({fill: typeof color === 'string' ? color : color.toRgbString()});
    canvas_puzzle.backgroundColor = canvas_puzzle_bg.fill;
    canvas_update_wzoom();
  });
  $control_canvas_h_minus.click(e => canvas_h_w_change({e, h: -canvas_size_btn_step}));
  $control_canvas_h_plus.click(e => canvas_h_w_change({e, h: canvas_size_btn_step}));
  $control_canvas_w_minus.click(e => canvas_h_w_change({e, w: -canvas_size_btn_step}));
  $control_canvas_w_plus.click(e => canvas_h_w_change({e, w: canvas_size_btn_step}));
  $controls_puzzle.on('change', '[name="control_canvas_zoom"]', e => {
    const zoom = parseInt($(e.currentTarget).val(), 10);
    if (zoom === 1) { canvas_disable_zoom(); }
    else { canvas_enable_zoom(zoom); }
  });
  //
  $control_pan_zoom_reset.click(reset_pan_zoom_controls);
  $control_wheel_zooms.click(update_pan_zoom_controls);
  //
  $control_pieces_load_fallback.change(e => {
    if (e.currentTarget.files && e.currentTarget.files.length && !piece_loading_block) {
      piece_loading_start(Array.from(e.currentTarget.files));
    }
  });
  $control_pieces_name_toggle.click(e => {
    canvas_pieces_name_toggle(e.currentTarget.checked);
  });
  $control_pieces_name_color.on('change move.spectrum', (e, color) => {
    canvas_pieces_name_color(typeof color === 'string' ? color : color.toRgbString());
  });
  $control_pieces_name_size.change(e => {
    canvas_pieces_name_size(e.currentTarget.value);
  });
  //
  $control_spiece_rotate_reset.click(() => {
    const obj = canvas_selection_last;
    if (obj) {
      obj.rotate(0).setCoords();
      update_rotate_scale(obj, {render: true});
    }
  });
  $control_spiece_rotate_left.click(() => {
    const obj = canvas_selection_last;
    if (obj) {
      obj.rotate(obj.get('angle') - 45).setCoords();
      update_rotate_scale(obj, {render: true});
    }
  });
  $control_spiece_rotate_right.click(() => {
    const obj = canvas_selection_last;
    if (obj) {
      obj.rotate(obj.get('angle') + 45).setCoords();
      update_rotate_scale(obj, {render: true});
    }
  });
  $control_spiece_scale_reset.click(() => {
    const obj = canvas_selection_last;
    if (obj && (obj.scaleX !== 1 || obj.scaleY !== 1)) {
      obj.set({scaleX: 1, scaleY: 1});
      canvas_keep_obj_inside(obj, {set_coords: true});
      update_rotate_scale(obj, {render: true});
    }
  });
  $control_spiece_scale_plus.click(() => {
    const obj = canvas_selection_last;
    if (obj) {
      const scale = get_btn_next_scale(obj.scaleX);
      if (scale !== obj.scaleX) {
        obj.set({scaleX: scale, scaleY: scale});
        canvas_keep_obj_inside(obj, {set_coords: true});
        update_rotate_scale(obj, {render: true});
      }
    }
  });
  $control_spiece_scale_minus.click(() => {
    const obj = canvas_selection_last;
    if (obj) {
      const scale = get_btn_next_scale(obj.scaleX, true);
      if (scale !== obj.scaleX) {
        obj.set({scaleX: scale, scaleY: scale});
        canvas_keep_obj_inside(obj, {set_coords: true});
        update_rotate_scale(obj, {render: true});
      }
    }
  });
  $control_spiece_flip_reset.click(() => {
    const obj = canvas_selection_last;
    if (obj && (obj.flipX || obj.flipY)) {
      obj.set({flipX: false, flipY: false}).setCoords();
      update_rotate_scale(obj, {render: true});
    }
  });
  $control_spiece_remove.click(() =>{
    const obj = canvas_selection_last;
    if (obj) {
      const is_multi = obj.type === 'activeSelection';
      if(confirm(`Do you want to delete the selected piece${is_multi ? 's' : ''}?`)) {
        if (is_multi) {
          //remove children
          const pieces = obj.getObjects().filter(piece => canvas_pieces.indexOf(piece) > -1);
          pieces.forEach(o => {
            canvas_puzzle.remove(o);
            canvas_pieces = canvas_pieces.filter(piece => piece !== o);
          });
          //deselect
          canvas_puzzle.discardActiveObject();
        }
        else {
          canvas_puzzle.remove(obj);
          canvas_pieces = canvas_pieces.filter(piece => piece !== obj);
        }
        //
        oload_osave_enable_disable();
      }
    }
  });
  //
  $control_save_img.click(() => {
    puzzle_save_img();
  });
  $control_save_local.click(() => {
    puzzle_save_local();
  });
  $control_save_json.click(() => {
    puzzle_save_json();
  });
  $control_load_json_fallback.change(e => {
    if (e.currentTarget.files && e.currentTarget.files.length) {
      puzzle_load_json(e.currentTarget.files[0]);
    }
  });
  $control_save_local_clear.click(() => {
    puzzle_save_local_clear();
  });
  //
  $control_save_online.click(puzzle_save_online);
  $control_load_online.click(puzzle_load_online);
  //piece popup
  $popup_pieces_cont.on('change', '.piece_img_cb', () => {
    $piece_load_popup_load.prop({disabled: !$popup_pieces_cont.find('.piece_img_cb:checked').length});
  });
  $popup_pieces_cont.on('change', '.piece_trans_cb', e => {
    const
      $transp_color = $(e.currentTarget).closest('.transp_color'),
      $piece_cont = $transp_color.closest('.piece_cont'),
      this_enabled = $transp_color.find('.piece_trans_cb:checked').length,
      any_enabled = this_enabled || $piece_cont.find('.piece_trans_cb:checked').length;
    if (any_enabled) { piece_loading_popup_recolor($piece_cont); }
    else { piece_loading_popup_reset($piece_cont); }
    $transp_color.toggleClass('enabled', this_enabled);
  });
  $popup_pieces_cont.on('click', '.piece_zoom', e => {
    const
      $piece_cont = $(e.currentTarget).closest('.piece_cont');
    $piece_load_popup_cont.toggleClass('piece_zoomed_in');
    $piece_cont.toggleClass('piece_zoomed_in');
    resize_do(true);
  });
  $popup_pieces_cont.on('change', '.piece_name', e => {
    const
      $piece_cont = $(e.currentTarget).closest('.piece_cont'),
      index = $piece_cont.data('index');
    piece_loaded_images_add_name[index] = e.currentTarget.value;
  });
  $popup_pieces_cont.on('change', '.transp_color .control_color', e => {
    const
      $piece_cont = $(e.currentTarget).closest('.piece_cont'),
      any_enabled = $piece_cont.find('.piece_trans_cb:checked').length;
    if (any_enabled) { piece_loading_popup_recolor($piece_cont); }
    else { piece_loading_popup_reset($piece_cont); }
  });
  $popup_pieces_cont.on('change', '.control_range', e => {
    const
      $transp_color = $(e.currentTarget).closest('.transp_color'),
      this_enabled = $transp_color.find('.piece_trans_cb:checked').length;
    if (this_enabled) { piece_loading_popup_recolor($transp_color.closest('.piece_cont')); }
  });
  $piece_load_popup_load.click(() => {
    $piece_load_popup.removeClass('show');
    pieces_add_popup();
    $popup_pieces_cont.find('.transp_color .control_color').spectrum('destroy');
  });
  $piece_load_popup_close.click(() => {
    piece_loading_popup_close();
  });
  //oload popup
  $oload_popup_close.click(oload_popup_close);
  $oload_popup_load_key.click(oload_popup_load_key);
  $oload_list_bodies.on('click', '.oload_popup_load', oload_popup_load);
  $oload_list_bodies.on('click', '.oload_popup_remove', oload_popup_remove);

  //osave popup
  $osave_popup_save.click(osave_popup_save);
  $osave_popup_close.click(osave_popup_close);
  $osave_popup_name.keyup(e => {
    if (e.which === 13) {
      if (!$osave_popup_save.prop('disabled')) {
        $osave_popup_save.click();
      }
    }
  });
  //infos
  $info_expand_open.click(e => {
    e.preventDefault();
    $(e.currentTarget).closest('.info_expand_wrap').toggleClass('open');
  });
  $info_expand_close.click(e => {
    e.preventDefault();
    $(e.currentTarget).closest('.info_expand_wrap').removeClass('open');
  });

  //resize
  $(window).on('resize orientationchange', resize_on);
  //popstate
  if (enable_oload_osave_history_state) {
    $(window).on('popstate', () => location.reload());
  }
});