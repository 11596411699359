const shows_pieces = [
  {name: 'Evil', updated: '01/31/2019', seasons: [
    {name: 'S01', episodes: [
      {name: 'E02', pieces: [
        {file_name: 'S01E02 00.07.23.png', angle: 0, scale: 1.31},
        {file_name: 'S01E02 00.31.58.png', angle: 136.5, scale: 1.02},
        {file_name: 'S01E02 00.37.17.png', angle: -90, scale: 1.28}
      ]},
      {name: 'E03', pieces: [
        {file_name: 'S01E03 00.10.01.png', angle: 74, scale: 1},
        {file_name: 'S01E03 00.19.29.png', angle: 1, scale: 1.25},
        {file_name: 'S01E03 00.33.59.png', angle: -89.25, scale: 1.05}
      ]},
      {name: 'E04', pieces: [
        {file_name: 'S01E04 00.07.45.png', angle: 0, scale: 1.08},
        {file_name: 'S01E04 00.08.23.png', angle: 0, scale: 1},
        {file_name: 'S01E04 00.29.51.png', angle: 0, scale: 1}
      ]},
      {name: 'E05', pieces: [
        {file_name: 'S01E05 00.00.55.png', angle: 0, scale: 1},
        {file_name: 'S01E05 00.14.58.png', angle: 25, scale: 1},
        {file_name: 'S01E05 00.31.14.png', angle: -92, scale: 1.05}
      ]},
      {name: 'E06', pieces: [
        {file_name: 'S01E06 00.01.52.png', angle: -0.75, scale: 1},
        {file_name: 'S01E06 00.08.06.png', angle: 0, scale: 1.25},
        {file_name: 'S01E06 00.35.29.png', angle: 0, scale: 1.29}
      ]},
      {name: 'E07', pieces: [
        {file_name: 'S01E07 00.00.33.png', angle: 0, scale: 1.05},
        {file_name: 'S01E07 00.02.08.png', angle: 0.75, scale: 1.26},
        {file_name: 'S01E07 00.02.26.png', angle: 0, scale: 1.26},
        {file_name: 'S01E07 00.02.41.png', angle: 0, scale: 1.24},
        {file_name: 'S01E07 00.08.17.png', angle: 0, scale: 1.24},
        {file_name: 'S01E07 00.19.35.png', angle: 0, scale: 1}
      ]},
      {name: 'E08', pieces: [
        {file_name: 'S01E08 00.05.37.png', angle: -117.5, scale: 1},
        {file_name: 'S01E08 00.05.59.png', angle: 50, scale: 1.29},
        {file_name: 'S01E08 00.09.30.png', angle: -90, scale: 1.25},
        {file_name: 'S01E08 00.13.40.png', angle: 126, scale: 1},
        {file_name: 'S01E08 00.16.59.png', angle: 168, scale: 1.01},
        {file_name: 'S01E08 00.27.14.png', angle: -90, scale: 1},
        {file_name: 'S01E08 00.28.40.png', angle: -48.75, scale: 1},
        {file_name: 'S01E08 00.35.29.png', angle: 90, scale: 1.26}
      ]},
      {name: 'E09', pieces: [
        {file_name: 'S01E09 00.03.35.png', angle: 16, scale: 1.04},
        {file_name: 'S01E09 00.09.31.png', angle: 0, scale: 1.05},
        {file_name: 'S01E09 00.13.38.png', angle: 0, scale: 1.05},
        {file_name: 'S01E09 00.19.33.png', angle: 0, scale: 1.05},
        {file_name: 'S01E09 00.28.32.png', angle: 89.5, scale: 1.05},
        {file_name: 'S01E09 00.38.20.png', angle: 85, scale: 1},
        {file_name: 'S01E09 00.39.30.png', angle: 51, scale: 1},
        {file_name: 'S01E09 00.41.37.png', angle: 90, scale: 1}
      ]},
      {name: 'E10', pieces: [
        {file_name: 'S01E10 00.00.57.png', angle: 0, scale: 1},
        {file_name: 'S01E10 00.04.38.png', angle: 0, scale: 1},
        {file_name: 'S01E10 00.19.03.png', angle: 0, scale: 1},
        {file_name: 'S01E10 00.21.36.png', angle: 0, scale: 1},
        {file_name: 'S01E10 00.27.26.png', angle: 0, scale: 1},
        {file_name: 'S01E10 00.31.04.png', angle: 0, scale: 1},
        {file_name: 'S01E10 00.35.41.png', angle: 0, scale: 1}
      ]},
      {name: 'E11', pieces: [
        {file_name: 'S01E11 00.14.49.png', angle: 0, scale: 1},
        {file_name: 'S01E11 00.24.27.png', angle: 0, scale: 1},
        {file_name: 'S01E11 00.27.41.png', angle: 0, scale: 1},
        {file_name: 'S01E11 00.29.04.png', angle: 0, scale: 1},
        {file_name: 'S01E11 00.36.48.png', angle: 0, scale: 1},
        {file_name: 'S01E11 00.37.33.png', angle: 0, scale: 1},
        {file_name: 'S01E11 00.38.12.png', angle: 0, scale: 1},
        {file_name: 'S01E11 00.40.20.png', angle: 0, scale: 1}
      ]},
      {name: 'E12', pieces: [
        {file_name: 'S01E12 00.04.30.png', angle: 0, scale: 1},
        {file_name: 'S01E12 00.05.51.png', angle: 0, scale: 1},
        {file_name: 'S01E12 00.20.25.png', angle: 0, scale: 1},
        {file_name: 'S01E12 00.23.16.png', angle: 0, scale: 1},
        {file_name: 'S01E12 00.23.55.png', angle: 0, scale: 1},
        {file_name: 'S01E12 00.27.31.png', angle: 0, scale: 1},
        {file_name: 'S01E12 00.38.53.png', angle: 0, scale: 1},
        {file_name: 'S01E12 00.39.00.png', angle: 0, scale: 1}
      ]},
      {name: 'E13', pieces: [
        {file_name: 'S01E13 00.07.11.png', angle: 0, scale: 1},
        {file_name: 'S01E13 00.07.39.png', angle: 0, scale: 1},
        {file_name: 'S01E13 00.17.25.png', angle: 0, scale: 1},
        {file_name: 'S01E13 00.20.32.png', angle: 0, scale: 1},
        {file_name: 'S01E13 00.20.52.png', angle: 0, scale: 1},
        {file_name: 'S01E13 00.30.17.png', angle: 0, scale: 1},
        {file_name: 'S01E13 00.34.50.png', angle: 0, scale: 1}
      ]}
    ]},
    {name: 'S02', episodes: [

    ]}
  ]}
];
shows_pieces.forEach(show => {
  const {seasons} = show;
  seasons.forEach(season => {
    //filter out episodes with no pieces
    season.episodes = season.episodes.filter(episode => episode.pieces.length);
    const {episodes} = season;
    //add name if needed (from file_name)
    episodes.forEach(episode => {
      const {pieces} = episode;
      pieces.forEach(piece => {
        if (!piece.hasOwnProperty('name')) {
          piece.name = piece.file_name.replace(/\.[a-z]+$/i, '').replace(/\./g, ':');
        }
      });
    });
    //add 'All' if needed
    if (
      episodes.length > 1 &&
      episodes.every(episode => episode.name !== 'All')
    ) {
      const all_pieces = [];
      episodes.forEach(episode => all_pieces.push(...episode.pieces));
      episodes.push({name: 'All', pieces: all_pieces});
    }
  });
});

export {shows_pieces};